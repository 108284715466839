import React from 'react'
import styled from 'styled-components'

const Separator = styled.span`
    color: #e6e6e6;
    margin:0 10px;
`

function* separate(parts: any[]) {
    let i = 0;
    for (let part of parts) {
        if (i++) yield <Separator key={i}>/</Separator>
        yield part
    }
}

export default (props: { children: any }) =>
    <React.Fragment>
        {[...separate(props.children)]}
    </React.Fragment>