import { Action, Reducer } from 'redux'
import * as api from '../api'
import { ThunkDispatch } from 'redux-thunk'
import { ApplicationState } from './index'
import { NetworkState } from './types'

export type State = {
    companyKey: string | null,
    networkState: NetworkState,
    company: api.Company | null
}

export interface CompanyChangedAction { type: 'COMPANY_CHANGED', payload: string }
export interface CompanyReceivedAction { type: 'COMPANY_RECEIVED', payload: api.Company }
export interface CompanyLoadError { type: 'COMPANY_LOAD_ERROR' }
export interface CompanyInvalidated { type: 'COMPANY_INVALIDATED' }
export interface UserRemoved { type: 'USER_REMOVED', payload: api.User }
export interface UserUpdated { type: 'USER_UPDATED', payload: api.User }
export interface ClustersChange { type: 'USER_UPDATED', payload: api.User }
type AnyAction = CompanyChangedAction | CompanyReceivedAction | CompanyLoadError | CompanyInvalidated | UserRemoved | UserUpdated

export const companyChanged = (key: string) =>
    (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => ApplicationState) => {
        const { companyProfile } = getState()
        if (companyProfile && companyProfile.companyKey !== key) {
            dispatch({ type: 'COMPANY_CHANGED', payload: key })
            api.fetchCompany(key)
                .then((result: api.Company) => dispatch({ type: 'COMPANY_RECEIVED', payload: result }))
                .catch(() => dispatch({ type: "COMPANY_LOAD_ERROR" }))
        }
    }

export const invalidateCompany = () =>  ({ type: 'COMPANY_INVALIDATED' })

export const removeUser = (user: api.User) =>
    (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => ApplicationState) => {
        const key = (getState().companyProfile || { companyKey: null }).companyKey
        key && api.deleteUser(key, user.email)
            .then(() => dispatch({ type: 'USER_REMOVED', payload: user }))
    }

export const updateUser = (user: api.User) =>
    (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => ApplicationState) => {
        const key = (getState().companyProfile || { companyKey: null }).companyKey
        key && api.updateUser(key, user.email, user)
            .then(() => dispatch({ type: 'USER_UPDATED', payload: user }))
    }

export const reducer: Reducer<State> =
    (state: State | undefined, action: Action): State => {
        const a = action as AnyAction
        state = state || { companyKey: null, company: null, networkState: 'wait' }
        switch (a.type) {
            case 'COMPANY_CHANGED':
                return { ...state, networkState: 'wait', companyKey: a.payload, company: null }
            case 'COMPANY_INVALIDATED':
                return { ...state, networkState: 'wait', companyKey: null, company: null }
            case 'COMPANY_RECEIVED':
                return { ...state, networkState: 'complete', company: a.payload }
            case 'COMPANY_LOAD_ERROR':
                return { ...state, networkState: 'error' }
            case 'USER_REMOVED':
                return state.company ?
                    { ...state, company: { ...state.company, users: state.company.users.filter(u => u !== a.payload) } }
                    : state
            case 'USER_UPDATED':
                const updated = a.payload
                return state.company ?
                    {
                        ...state, company: {
                            ...state.company,
                            users: state.company.users.map(u => u.email === updated.email ? updated : u)
                        }
                    }
                    : state
            default:
                return state
        }
    }
