import styled from 'styled-components'

export default styled.table`
    width:100%;
    thead { 
        background-color:#FAFAFA;
        border: 1px solid #F0EEEE;
    }
    th { 
        letter-spacing:1px;
        text-transform: uppercase;
        color: #909090;
        font-size: 0.8rem;
        font-weight: 400;
    }
    th,td { 
        padding: 8px 16px;
    }
    tbody tr {
        border-bottom: 1px solid #EAEAEA;
    }

    col {
        width:50%;
    }
    col.min {
        width:0;
    }
`