import * as React from 'react'
import Form from '../Form'
import { Label, Input, FormGroup, Button, Alert } from 'reactstrap'
import { FormSectionBorder as FormSection } from '../theme/PageSection'
import MustBeLoggedIn from './MustBeLoggedIn'
import webAuth0 from './auth0Config'
import { connect } from 'react-redux'
import { ApplicationState } from '../../store'
import { AuthUserProfile } from '../../store/Auth'
import AuthLayout from './AuthLayout'

type Props = { identity: AuthUserProfile | null | undefined }

const EditForm = ({ identity }: Props) => {
    const name = (identity && identity.displayName) || ""
    const [passwordSent, setPasswordSent] = React.useState(false)

    const changePassword = () => new Promise((resolve, reject) =>
        webAuth0.changePassword({
            connection: "Email-Password-Authentication",
            email: identity!.email
        }, err => {
            if (err) reject(err)
            else {
                resolve()
                setPasswordSent(true)
            }
        }))

    return <>
        <h1>Edit your profile</h1>
        <FormGroup>
            <Label>Full name</Label>
            <Input type="text" name="name" required
                value={name}
                readOnly />
        </FormGroup>
        <FormGroup>
            <Label>Password</Label>
            <Form onSubmit={changePassword} >
                <p><Button onClick={changePassword}>Change your password</Button></p>
            </Form>
            {passwordSent &&
                <Alert color="success">We've sent you a link to change your password. Please check your email.</Alert>}
        </FormGroup>
    </>
}

const EditProfile = ({ identity }: Props) => <AuthLayout>
    <FormSection>
        <MustBeLoggedIn>
            <EditForm identity={identity} />
        </MustBeLoggedIn>
    </FormSection>
</AuthLayout>

export default connect
    ((state: ApplicationState) => ({ identity: state.auth && state.auth.identity }))
    (EditProfile)