import React from 'react'
import { RequireAdmin } from '../auth/RequireUser'
import ContextMenu from '../theme/ContextMenu'
import * as api from '../../api'
import { DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'

type Props = { companyKey: string, cluster: api.Cluster }

export default ({ companyKey, cluster }:Props)  => <RequireAdmin>
    <ContextMenu className="t-project-menu">
        <DropdownItem
            className="t-manage-access"
            tag={Link}
            to={`/profile/${companyKey}/clusters/${cluster.key}#manage-access`}>Manage access</DropdownItem>
    </ContextMenu>
</RequireAdmin>
