import styled from 'styled-components'

interface IActivatableButton { active: boolean }

export default styled.button<IActivatableButton>`
    padding: 10px 30px;
    cursor: pointer;
    opacity: 0.4;
    border: 0;
    background: white;

    &:focus {
        outline: 0;
    }

    &:hover {
        background: #e0e0e0;
    }

    ${({ active }) =>
        active &&
        `
        border: 0;
        background: #f0f0f0;
        opacity: 1;
    `}
`
