import * as React from 'react'
import * as ReactStrap from 'reactstrap'
import { NavLink as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import colours from './colours'

const navbarHeight = `58px`

export const Navbar =  (props:any) => {
    const Styled = styled(ReactStrap.Navbar)`
        padding:0;
        height:${navbarHeight};
    `
    return <Styled {...props} />
}

const navStyling = `
    height:${navbarHeight};
    margin: 0 8px;
    display:flex;
    font-weight: 600;
    align-items:center;
    border-bottom:2px solid transparent;
    color:${colours.Indigo};
    &:hover {
        text-decoration: none;
        color:${colours.Black};
    }
    &.active {
        border-bottom:2px solid ${colours.Coral};
        color:${colours.Coral};
    }`

export const NavLink = (props: any) => {
    const Styled = styled(RouterLink)`${navStyling}`
    return <Styled {...props} />
}

export const NavAnchor = (props: any) => {
    const Styled = styled.a`${navStyling}`
    return <Styled {...props} />
}