import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom'
import MustBeLoggedIn from './components/auth/MustBeLoggedIn'
import InviteToTeam from './components/customer/InviteToTeam'
import Company from './components/customer/Company'
import EditCompany from './components/customer/EditCompany'
import Layout from './components/Layout'
import { withCompanyFromRoute } from './components/customer/CompanyLoader'
import NewProject from './components/customer/NewProject'
import EditCluster from './components/customer/EditCluster'
import SDKDownloads from './components/customer/SdkDownloads'

export default () => {
    let { path }: any = useRouteMatch()

    return (
        <MustBeLoggedIn>
            <Layout>
                <Switch>
                    <Route exact path={`${path}/:companyKey`} component={withCompanyFromRoute(Company)} />
                    <Route exact path={`${path}/:companyKey/edit`} component={EditCompany} />
                    <Route exact path={`${path}/:companyKey/invite`} component={InviteToTeam} />
                    <Route exact path={`${path}/:companyKey/new-project`} component={NewProject} />
                    <Route exact path={`${path}/:companyKey/clusters/:clusterKey`} component={EditCluster} />
                    <Route exact path={`${path}/:companyKey/sdk-downloads`} component={withCompanyFromRoute(SDKDownloads)} />
                </Switch>
            </Layout>
        </MustBeLoggedIn>
    )
}