import React from 'react'
import styled from 'styled-components'
import { SectionHeading, SubFullWidthSection } from '../theme/PageSection'
import { Company } from '../../api'

export const ProductNameAether     = "Aether"
export const ProductNameMuxer      = "Muxer"
export const ProductNamePlatform   = "Platform SDK"

// Styling for rendering list of product checkboxes.
export const ProductFlagPanel = styled.div`
    >* {
        margin-left: 32px;
    }
`

export default ((company: Company) => {
    return <>
        <SectionHeading>Licensed Products</SectionHeading>
        <SubFullWidthSection id="license-info" data-testid="licensed-products">
            {company.licenses.aether ? <p>{ProductNameAether}</p> : null}
            {company.licenses.muxer ? <p>{ProductNameMuxer}</p> : null}
            {company.licenses.platform ? <p>{ProductNamePlatform}</p> : null}
            {(!company.licenses.aether && !company.licenses.muxer && !company.licenses.platform) ? <p>None</p> : null}
            </SubFullWidthSection>
        </>
})
