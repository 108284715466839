import React from 'react'
import { useState, useEffect } from 'react'
import { PageHeading, WideSection } from '../theme/PageSection'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import * as api from '../../api'
import Table from '../theme/Table'
import styled from 'styled-components'


const DownloadsLink = styled(Link)`
    margin-right: 10px;
`

const Create = <Link className="btn btn-secondary btn-sm" id="create-personal-account" to="personal-accounts/new">Create New</Link>
const Downloads = <DownloadsLink className="" id="platform-sdk-downloads" to="/platform-sdk-downloads">Downloads</DownloadsLink>
const Extras = <>
    {Downloads}
    {Create}
    </>


const PersonalAccountTable = () => {
    const [personalAccounts, setPersonalAccounts] = useState<api.PersonalAccountSummary[]>([]);

    const handleDelete = (key: string) => {
        api.deletePersonalAccount(key)
            .then(() => {
                setPersonalAccounts(personalAccounts.filter(a => a.key !== key))
            })
            .catch((e) => console.error("could not delete personalAccount", e))
    }

    useEffect(() => {
        (async () => {
            const accounts = await api.listPersonalAccounts()
            setPersonalAccounts(accounts)
        })()
    }, [])

    return <Table id="personal-account-list" >
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Created at</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {personalAccounts && personalAccounts.map( c => <tr key={c.key}>
                    <td>{c.username}</td>
                    <td>{c.created}</td>
                    <td><Button onClick={() => handleDelete(c.key)}>Delete</Button></td>
                </tr>
                )}
            </tbody>
        </Table >
}
export default () => <React.Fragment>
    <PageHeading extras={Extras}>Personal Accounts</PageHeading>
    <WideSection>
        <PersonalAccountTable />
    </WideSection>
</React.Fragment>
