import { ApplicationState } from '../../store'
import { connect } from 'react-redux'
import { AuthState } from '../../store/Auth'
import React from 'react'
import history from '../../history'

const MustBeLoggedIn: React.FunctionComponent<{ auth: AuthState | undefined, children: JSX.Element }> =
	({ auth, children }) => {
		React.useEffect(() => {
			if (auth && auth.isLoggedIn === false) history.push('/auth/signin', {redirectPath: history.location.pathname})
		})

		return <React.Fragment>
			{auth && auth.isLoggedIn && children}
		</React.Fragment>
	}

export default connect((state: ApplicationState) => ({ auth: state.auth }))(MustBeLoggedIn)