import * as React from 'react'
import { NavItem, NavLink, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import { connect, DispatchProp } from 'react-redux'
import { ApplicationState } from '../../store'
import styled from 'styled-components'
import { AuthState } from '../../store/Auth'
import webAuth0 from './auth0Config'
import { AuthUserProfile } from '../../store/Auth'

const Circle = styled(DropdownToggle)`
	width:2em;
	height:2em;
	border-radius:100%;
	border:none;
	padding:0;
	span {
		margin:auto;
	}
`

const Authenticated = (props: {
	identity: AuthUserProfile
}) => {
	const logout = () => webAuth0.logout({ returnTo: window.location.origin })
	const initials = props.identity.displayName ?
		props.identity.displayName.split(' ').slice(0,2).map(x => x[0]) : 
		""

	return <UncontrolledDropdown nav inNavbar>
		<Circle id="user-auth" color="primary"><span>{initials}</span></Circle>
		<DropdownMenu right>
			{ !props.identity.isSso &&
				<DropdownItem id="edit-profile-link" href='/auth/editProfile'>Your profile</DropdownItem> }
			<DropdownItem id="logout-link" onClick={logout}>Log out</DropdownItem>
		</DropdownMenu>
	</UncontrolledDropdown>
}

const Unauthenticated = () =>
	<NavItem>
		<NavLink id="login-link" href="/auth/signin">Sign in</NavLink>
	</NavItem>

const Login = (props: AuthState & DispatchProp<any>) => {
	if (props.isLoggedIn === true) return <Authenticated identity={props.identity!} />
	else if (props.isLoggedIn === false) return <Unauthenticated />
	else return null
}

export default connect(
	(state: ApplicationState) => state.auth
)(Login)
