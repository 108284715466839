import * as React from 'react'
import horizontalLogo from '../../theme/logo-dark.svg'
import styled from 'styled-components'

const Logo = styled.img`
    height:2em;
    margin:32px;
`

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <header>
            <Logo src={horizontalLogo} alt="Hadean" />
        </header>
        {props.children}
    </React.Fragment>
)
