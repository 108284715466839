import * as React from 'react'
import Form from '../Form'
import { Label, Input, FormGroup, Button } from 'reactstrap'
import { FormSectionBorder as FormSection } from '../theme/PageSection'
import { RouteComponentProps } from 'react-router-dom'
import webAuth0 from './auth0Config'
import AuthLayout from './AuthLayout'

type Props = RouteComponentProps<{}, {}, { email: string }>

const ForgotPassword = (props: Props) => {
    const [email, setEmail] = React.useState(props.location.state ? props.location.state.email : '')

    const submit = () => new Promise((resolve, reject) =>
        webAuth0.changePassword({
            connection: "Email-Password-Authentication",
            email
        }, err => {
            if (err) reject(err)
            else resolve()
        }))

    return <AuthLayout>
        <FormSection>

            <h1>Reset your password</h1>

            <Form onSubmit={submit}
                redirect={{ pathname: "/auth/signin", state: { message: "Please check your email and follow the instructions to reset your password." } }}>

                <FormGroup>
                    <Label>Your email address</Label>
                    <Input type="email" name="email" required
                        value={email}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)} />
                </FormGroup>

                <Button type="submit">Send password reset email</Button>

            </Form>

        </FormSection>
    </AuthLayout>
}

export default ForgotPassword