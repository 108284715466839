import * as React from 'react'
import { companyChanged } from '../../store/companyProfile'
import { ReactComponentLike } from 'prop-types'
import { ApplicationState } from '../../store'
import { State as CompanyState } from '../../store/companyProfile'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Company } from '../../api'
import { LoadingState } from '../LoadingState'

type LoaderProps = CompanyState & DispatchProp<any>
export type CompanyLoaderProps = {
    company: Company,
    companyKey: string
} & DispatchProp<any>

export const withCompanyLoader = (Inner: ReactComponentLike) => {
    class Loader extends React.Component<LoaderProps>{
        render() {
            const { companyKey, networkState, company, dispatch, ...rest } = this.props
            return <LoadingState state={networkState}>
                <Inner company={company} companyKey={companyKey} dispatch={dispatch} {...rest} />
            </LoadingState >
        }
    }

    return connect((state: ApplicationState) => state.companyProfile)(Loader)
}

export const withCompanyFromRoute = (Inner: ReactComponentLike) =>
    connect()(
        (props: RouteComponentProps<{ companyKey: string }> & DispatchProp<any>) => {
            const WrappedInner = withCompanyLoader(Inner)
            React.useEffect(() => { props.dispatch(companyChanged(props.match.params.companyKey)) }, [props])
            return <WrappedInner {...props} />
        })