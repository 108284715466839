import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import SignIn from './components/auth/SignIn'
import SignUp from './components/auth/SignUp'
import Password from './components/auth/Password'
import EditProfile from './components/auth/EditProfile'

export default () => <Switch>
    <Route exact path='/auth/signin' component={SignIn} />
    <Route exact path='/auth/signup' component={SignUp} />
    <Route exact path='/auth/password' component={Password} />
    <Route exact path='/auth/editProfile' component={EditProfile} />
</Switch >