import React from 'react'
import colours from '../theme/colours'
import { ResponsiveBar, BarExtendedDatum } from '@nivo/bar'
import * as api from '../../api'

type Data = api.MetricResult["data"]
export default ({ data }: { data: Data }) => {
    const dataFormat = data.map(({ value, time }) => ({
        value,
        timeFormat: new Date(time).toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })
    }))

    const tooltip = ({ data }: BarExtendedDatum) => <div>
        {((data["value"] as number) / 1024)
            .toLocaleString([], { maximumFractionDigits: 1, useGrouping: true })}kB
    </div>

    return <div style={{ width: "100%", height: "160px" }}>
        <ResponsiveBar data={dataFormat}
            keys={['value']}
            indexBy="timeFormat"
            enableLabel={false}
            colors={[colours.Orange]}
            margin={{ bottom: 50 }}
            tooltip={tooltip}
            axisBottom={{
                tickValues: 10,
                tickRotation: -90
            }}
        />
    </div>
}