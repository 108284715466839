import * as React from 'react'
import { Collapse, Container, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap'
import LoginStatus from './auth/NavbarLogin'
import logo from '../theme/logo-dark.svg'
import requireUser from './auth/RequireUser'
import styled from 'styled-components'
import { Navbar, NavLink, NavAnchor } from './theme/Nav'
import colours from './theme/colours'
import { UserState } from '../store/user'

const BrandStyle = styled(NavbarBrand)`
    img { height:1.2em; }
    padding:0 16px 0 8px;
    border-right:1px solid ${colours.Stone};
`
const Brand = (props:any) => <BrandStyle {...props}><img src={logo} alt="Hadean"/></BrandStyle>

const Right = styled.div`
    margin-left:auto;
    display: inline-flex;
`

const NavItemList = styled.ul`
    flex:1;
    align-items: center;
`


class NavMenu extends React.PureComponent<{user: UserState}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    }

    public render() {

        const isSupportUser = this.props.user && this.props.user.isSupportUser

        return (
			<header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow" light>
                    <Container>
                        <Brand to="/" />
                        <NavbarToggler onClick={this.toggle} className="mr-2"/>
                        <Collapse className="d-sm-inline-flex" isOpen={this.state.isOpen} navbar>
                            <NavItemList className="navbar-nav flex-grow">
                                {
                                    isSupportUser
                                    && (<NavItem>
                                        <NavLink id="personal-accounts-link" to="/personal-accounts">Manage Personal Accounts</NavLink>
                                    </NavItem>)
                                }
                                {
                                    isSupportUser
                                    && (<NavItem>
                                        <NavLink id="companies-link" to="/companies">Companies</NavLink>
                                    </NavItem>) 
                                }
                                <NavAnchor href="https://docs.hadean.com" target="_blank">Documentation</NavAnchor>
                                <Right>
                                    <LoginStatus />
                                </Right>
                            </NavItemList>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        )
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
}

export default requireUser(NavMenu)