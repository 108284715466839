import * as React from 'react'
import { connect } from 'react-redux'
import { fetchUser, UserState } from '../../store/user'
import { ApplicationState } from '../../store'
import { ReactComponentLike } from 'prop-types'

export default function requireUser(Component: ReactComponentLike) {
    const RequireUser = (props: { fetchUser: () => void, identity: any }) => {
        React.useEffect(props.fetchUser, [props.identity])
        return <Component {...props} />
    }

    return connect(
        ({ user, auth }: ApplicationState) => ({ user, identity: auth && auth.identity }),
        { fetchUser }
    )(RequireUser)
}

const RequireAdminInner: React.FunctionComponent<{ user: UserState }> =
    ({ user, children }) => (user && user.admin) ? <>{children}</> : null

export const RequireAdmin = requireUser(RequireAdminInner)

const RequireSupportInner: React.FunctionComponent<{ user: UserState }> =
    ({ user, children }) => (user && user.isSupportUser) ? <>{children}</> : null

export const RequireSupport = requireUser(RequireSupportInner)