import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom'
import MustBeLoggedIn from './components/auth/MustBeLoggedIn'
import NewCompany from './components/support/NewCompany'
import CompanyList from './components/support/CompanyList'
import Layout from './components/Layout'

export default () => {
    let { path }: any = useRouteMatch()

    return (
        <MustBeLoggedIn>
            <Layout>
                <Switch>
                    <Route exact path={`${path}`} component={CompanyList} />
                    <Route exact path={`${path}/new`} component={NewCompany} />
                </Switch>
            </Layout>
        </MustBeLoggedIn>
    )
}