import React from 'react'
import { LoadingState, useLoadingState } from '../LoadingState'
import { RouteComponentProps } from 'react-router'
import { fetchCluster, Company, ClusterDetail, patchCluster } from '../../api'
import { PageHeading, FullWidthSection } from '../theme/PageSection'
import { withCompanyFromRoute } from './CompanyLoader'
import Breadcrumb from '../theme/Breadcrumb'
import { Link } from 'react-router-dom'
import ClusterAccess from './ClusterAccess'
import Button from 'reactstrap/lib/Button'
import Form from '../Form'

type Props = RouteComponentProps<{ clusterKey: string }> & { company: Company }

export default withCompanyFromRoute(
    (props: Props) => {
        const clusterKey = props.match.params.clusterKey
        const { state, result: cluster } = useLoadingState(fetchCluster, clusterKey)
        const { company } = props
        const [patch, setPatch] = React.useState<Partial<ClusterDetail>>({})

        return <LoadingState state={state}>
            {cluster && <>
                <PageHeading>
                    <Breadcrumb>
                        <Link to={`/profile/${company.key}`}>{company.name}</Link>
                        {cluster.name}
                    </Breadcrumb>
                </PageHeading>
                <Form
                    onSubmit={() => patchCluster(clusterKey, patch)}
                    successMessage="The project has been updated"
                    >

                    <ClusterAccess company={company} cluster={cluster}
                        onChange={(users: ClusterDetail["users"]) => setPatch({ ...patch, users })} />

                    <FullWidthSection>
                    <Button type="submit">Save changes</Button>
                    </FullWidthSection>
                </Form>
            </>}
        </LoadingState>
    }
)