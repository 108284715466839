import React from 'react'
import { FormGroup, Label, Input, Button } from 'reactstrap'
import { FormSection, PageHeading } from '../theme/PageSection'
import { postPersonalAccount } from '../../api'
import Form from '../Form'

interface State {
    username: string
}

export default class NewPersonalAccount extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props)
        this.state = {
            username: ''
        }
    }
    submit() {
        const { username } = this.state
        return postPersonalAccount({ username })
    }
    render() {
        const { username } = this.state
        return <React.Fragment>
            <PageHeading>New personal account</PageHeading>
            <FormSection>
                <Form onSubmit={this.submit.bind(this)}
                    successMessage="The new personal account was added.">

                    <FormGroup>
                        <Label>Personal Account email address</Label>
                        <Input type="email" name="email" required
                            value={username}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => this.setState({ username: e.currentTarget.value })} />
                    </FormGroup>

                    <Button type="submit">Create</Button >
                    
                </Form>

            </FormSection>
        </React.Fragment>
    }
}