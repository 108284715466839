import React from 'react'
import Table from '../theme/Table'
import { User, Company } from '../../api'
import Badge from '../theme/Badge'

type Props = {
    company: Company,
    selected: string[],
    onChange: (selected: string[]) => void,
    adminsPreselected?: boolean
}

export default (props: Props) => {
    const selectAll = (checked: boolean) => {
        props.onChange(checked ?
            props.company.users.map(u => u.email) :
            [])
    }

    const selectUser = (checked: boolean, key: string) => {
        props.onChange(checked ?
            [...props.selected, key] :
            props.selected.filter(k => k !== key))
    }

    const userChecked = (user: User) =>
        props.selected.includes(user.email) ||
        (props.adminsPreselected && user.roles.admin)

    const userDisabled = (user: User) => props.adminsPreselected && user.roles.admin

    return <Table id="user-list">
        <colgroup>
            <col className="min" />
            <col />
            <col />
        </colgroup>
        <thead>
            <tr>
                <th>
                    <input type="checkbox" className="t-select-all" onChange={(e: React.FormEvent<HTMLInputElement>) => selectAll(e.currentTarget.checked)} />
                </th>
                <th>Name</th>
                <th>Email</th></tr>
        </thead>
        <tbody>
            {props.company.users.map((user: User) => <tr key={user.email}>
                <td>
                    <input type="checkbox"
                        checked={userChecked(user)}
                        disabled={userDisabled(user)}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => selectUser(e.currentTarget.checked, user.email)} />
                </td>
                <td>
                    {user.name || "—"}
                    {" "}
                    {user.roles && user.roles.admin && <Badge>Admin</Badge>}
                </td>
                <td>{user.email}</td>
            </tr>
            )}
        </tbody>
    </Table>
}