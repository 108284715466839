import React from 'react'
import colours from '../theme/colours'
import styled from 'styled-components'

const MetricHeader = styled.h6`
    color: ${colours.Indigo80};
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 6px;
    margin-bottom: 3px;
`
const MetricBody = styled.p`
    color: black;
    font-size: 36px;
    line-height: 36px;
`

const RightAlign = styled.div`
    text-align: right;
`

export default ({ title, metric }: { title: string, metric: string }) => {
    return <RightAlign>
        <MetricHeader>{title}</MetricHeader>
        <MetricBody>{metric}</MetricBody>
    </RightAlign>
}