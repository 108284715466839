import * as React from 'react'
import colours from '../theme/colours'
import HadeanStoneBackground from '../../theme/stone-bg.svg'
import styled from 'styled-components'
import logo from '../../theme/logo.svg'

const widescreen = "min-width: 860px"

const Logo = styled.img`
    height:3em;
    margin:2em 0 2em 5vw;
    &.wide { 
        display:none;
        @media (${widescreen}) {
            display:block;
            position:absolute;
        }
    }
`

const NarrowHeader = styled.div`
        order: 0;
        @media (${widescreen}) {
            display:none;
        }
`
const LeftPanel = styled.div`
        @media (${widescreen}) {
            flex:40;
            display:flex;
            order:0;
        }
        order: 2;
`

const RightPanel = styled.div`
        padding:2em;
        align-items:center;
        justify-items:center;
        justify-content:center;
        background: url(${HadeanStoneBackground})  bottom right;
        background-size:cover;
        order: 1;
        @media (${widescreen}) {
            order:0;
            flex:60;
            clip-path:polygon(25vw 0, 100% 0, 100% 100%, 0 100%);
            display:flex;
            >* { 
                margin-left:20vw; 
                flex: 1;
                align-self:center; 
            }
        }
`

const PartitionStyle = styled.div`
    background-color: ${colours.Indigo};
    display:flex;
    flex-direction:column;
    @media (${widescreen}) {
        flex-direction:row;
        height:100vh;
    }
    color:white;
    button {
      width:100%;
    }
    a { 
        color:${colours.Orange};
    }
`

const BrandingPartition: React.FunctionComponent<any> = ({ children: [lhs, rhs] }, ) =>
    <PartitionStyle>
        <NarrowHeader>
            <Logo src={logo} alt="Hadean" />
        </NarrowHeader>
        <LeftPanel>
            <Logo src={logo} className="wide" alt="Hadean" />
            {lhs}
        </LeftPanel>
        <RightPanel>{rhs}</RightPanel>
    </PartitionStyle>

export default BrandingPartition