import * as React from 'react'
import { Alert } from 'reactstrap'
import Spinner from './theme/Spinner'


export type NetworkState = "wait" | "error" | "complete"
type LoadingStateProps = { state: NetworkState, children: any }
export const LoadingState = ({ state, children }: LoadingStateProps) => {
    if (state === 'wait')
        return <div className="d-flex justify-content-center"><Spinner /></div>
    else if (state === 'error')
        return <Alert color="danger">There was a problem retrieving this information</Alert>
    else
        return <React.Fragment>{children}</React.Fragment>
}

export const useLoadingState = <T, Args extends any[]>(
    loadFunction: (...args: Args) => Promise<T>,
    ...args: any
) => {
    const [state, setState] = React.useState<NetworkState>("wait")
    const [result, setResult] = React.useState<T | null>(null)
    const reload = () => {
        setState("wait")
        loadFunction(...args)
            .then((result: T) => {
                setResult(result)
                setState("complete")
            })
            .catch(() => setState("error"))
    }
    React.useEffect(reload, args)
    return { state, result, reload }
}