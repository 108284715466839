
import './custom.scss'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store from './store'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import history from './history'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

if (process.env.NODE_ENV === 'production') {
    const reactPlugin = new ReactPlugin()
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history }
            },
            isCookieUseDisabled: true
        }
    })
    appInsights.loadAppInsights()
}

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'))

registerServiceWorker()
