import React from 'react'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import styled from 'styled-components'

const DropdownToggleStyle = styled(DropdownToggle)`
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    background-color:#f4f4f4;
`

export default ({className, children}:any) =>
    <UncontrolledDropdown>
        <DropdownToggleStyle className={className} color="light">⋮</DropdownToggleStyle>
        <DropdownMenu right>
            {children}
        </DropdownMenu>
    </UncontrolledDropdown>