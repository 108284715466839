import * as React from 'react'
import Form from '../Form'
import { Label, Input, FormGroup, Button, FormFeedback } from 'reactstrap'
import { RouteComponentProps } from 'react-router-dom'
import googleLogo from './GoogleG.svg'
import styled from 'styled-components'
import webAuth0 from './auth0Config'
import Divider from '../theme/Divider'
import Alert from 'reactstrap/lib/Alert'
import { Auth0Error } from 'auth0-js'
import TextLink from '../theme/TextLink'
import BrandingPartition from '../theme/BrandingPartition'
import hadeanIcon from '../../theme/icons/__triangle.svg'
import blogIcon from '../../theme/icons/blog.svg'
import docIcon from '../../theme/icons/__book2.svg'
import Footer from '../Footer'
import themes from '../theme/colours'
import TechStackImage from '../../theme/tech-stack.png'

const ProviderLogo = styled.img`
    width:1.5em;
    margin-right:1em;
`
const LeftPanel = styled.div`
        display:flex;
        width:100%;
        flex-direction:column;
`
const LeftPanelContent = styled.div`
        flex:1;
        display: flex;
        flex-direction:column;
        justify-content: center;
`
const RightPanel = styled.div`
    >* {
        margin:0 auto;
        max-width: 24em;
    }
`

const LinkSectionStyle = styled.div`
    display:flex;
    justify-items:center;
    justify-content:center;
    margin:2em;
    a {
        display:flex;
        flex-direction: column;
        align-items:center;
        flex:1;
        text-align:center;
        color:white;
        font-weight:600;
        padding:1em 0;
        transition:all 0.4s linear;
        img { 
            display:block;
            height:3em;
            margin-bottom:1em;
        }
        :hover {
            text-decoration:none;
            border: 1px solid ${themes.Orange60}
        }
    }
`

const WhiteBackgroundFormFeedback = styled(FormFeedback)`
    background-color: ${themes.White};
    border: 1px solid ${themes.Coral};
    padding: 3px 0px 3px 30px;
    margin-top: 10px;
    border-radius: 10px;
`

const SignInButton = (props: any) => {
    const Styled = styled(Button)`
    :hover:disabled #tos-tooltip {
        visibility: visible;
    }
    :disabled {
        cursor: not-allowed;
        pointer-events: all !important;
    }`

    return <Styled {...props} />
}

const Tooltip = styled.span`
    visibility: hidden;
    width: 140px;
    background-color: ${themes.Indigo};
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
`

const ToSCheckBox = styled.input`
    margin-right: 10px;
`

const LinkSection = () => <LinkSectionStyle>
    <a href="https://www.hadean.com"><img src={hadeanIcon} alt="" /> Hadean<br />Website</a>
    <a href="https://www.hadean.com/blog"><img src={blogIcon} alt="" /> Hadean<br />Blog</a>
    <a href="https://docs.hadean.com"><img src={docIcon} alt="" /> Developer<br />Documentation</a>
</LinkSectionStyle>


type Props = RouteComponentProps<{}, {}, { redirectPath: string, message: string | undefined }>


const SignIn = (props: Props) => {
    const [checked, setChecked] = React.useState(false);
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [error, setError] = React.useState<string | null>(null)

    const submitAuth0 = () => new Promise((resolve, reject) => {
        webAuth0.login({
            email,
            password,
            state: props.location.state && props.location.state.redirectPath,
            realm: "Email-Password-Authentication"
        }, (err) => {
            if (err) reject(err)
            else resolve()
        })
    })

    const onError = (e: Auth0Error) => {
        switch (e.error) {
            case "access_denied":
                setError(e.error)
                return true
            default: return false
        }
    }

    const signInGoogle = () => webAuth0.authorize({
        connection: 'google-oauth2',
        mode: "login",
        state: props.location.state && props.location.state.redirectPath
    })

    const widescreen = "max-width: 1025px"

    const TechStack = styled.img`
        display: block;
        top: -100px;
        left: -180px;
        position: absolute;
        height: 950px;
        opacity: 0.8;
        max-height: 100%;
        @media (${widescreen}) {
            display: none;
        }
    `

    return <div id="signin-form">
        <BrandingPartition>
            <LeftPanel>
                <LeftPanelContent>
                    <TechStack src={TechStackImage} alt="tech stack image" />
                    {props.location.state && props.location.state.message && <Alert color="warning">{props.location.state.message}</Alert>}
                </LeftPanelContent>
                <LinkSection />
                <Footer />
            </LeftPanel>
            <RightPanel>
                <Form onSubmit={submitAuth0} onError={onError}>
                    <p><TextLink to='./signup'>Create a new account</TextLink></p>

                    <FormGroup>
                        <Label>Email address</Label>
                        <Input style={{'borderRadius': "10px", 'border': "none"}} type="email" name="email" required
                            invalid={error === "access_denied"}
                            value={email}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)} />
                    </FormGroup>

                    <FormGroup>
                        <Label>Password</Label>
                        <Input style={{'borderRadius': "10px", 'border': "none"}} type="password" name="password" required
                            invalid={error === "access_denied"}
                            value={password}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)} />

                        <WhiteBackgroundFormFeedback>That's the wrong email address or password.</WhiteBackgroundFormFeedback>
                    </FormGroup>

                    <p><TextLink to={{
                        pathname: '/auth/password',
                        state: { email }
                    }}>Forgot your password?</TextLink></p>

                    <label htmlFor="tos">
                        <ToSCheckBox
                            id="tos"
                            type="checkbox"
                            name="tos"
                            value="Terms Of Service"
                            checked={checked}
                            onChange={() => setChecked(!checked)} />Confirm that you accept our <a href="https://hadean.com/terms/">Terms and Conditions</a> and <a href="https://hadean.com/terms/privacy-policy/">Privacy Policy</a> for use of the SDK.
                    </label>

                    <SignInButton
                        style={{
                            'marginTop': "15px", 
                            'color': themes.Indigo, 
                            // 'border': `1px solid ${themes.Indigo}`,
                            'borderRadius': "10px",
                            'backgroundColor': `${themes.Indigo20}`
                        }}
                        type="submit"
                        color="secondary"
                        disabled={!checked}
                    >Sign in <Tooltip id="tos-tooltip">Confirm Terms of Service</Tooltip>
                    </SignInButton>

                    <Divider>or</Divider>

                    <p>
                        <SignInButton
                            style={{
                                'color': themes.Coral, 
                                // 'border': `1px solid ${themes.Indigo}`,
                                'borderRadius': "10px",
                                'backgroundColor': `${themes.Coral20}`
                            }}
                            id="google-login"
                            onClick={signInGoogle}
                            disabled={!checked}>
                            <ProviderLogo src={googleLogo} /> Sign in with Google
                            <Tooltip id="tos-tooltip">Confirm Terms of Service</Tooltip>
                        </SignInButton>{' '}
                    </p>
                </Form>
            </RightPanel>
        </BrandingPartition>
    </div>
}

export default SignIn
