import React from 'react'
import { PageHeading, WideSection } from '../theme/PageSection'
import { Link } from 'react-router-dom'
import * as api from '../../api'
import Table from '../theme/Table'
import { LoadingState, useLoadingState } from '../LoadingState'

const Create = <Link className="btn btn-secondary btn-sm" id="create-company" to="companies/new">Create New</Link>

const CompanyTable = () => {
    const { state, result: companies } = useLoadingState(api.listCompanies)
    return <LoadingState state={state}>
        <Table id="company-list" >
            <colgroup>
                <col />
            </colgroup>
            <thead>
                <tr><th>Name</th></tr>
            </thead>
            <tbody>
                {companies && companies.map(c => <tr key={c.name}>
                    <td><Link to={`/profile/${c.key}`}>{c.name}</Link></td>
                </tr>
                )}
            </tbody>
        </Table >
    </LoadingState>
}
export default () => <React.Fragment>
    <PageHeading extras={Create}>Companies</PageHeading>
    <WideSection>
        <CompanyTable />
    </WideSection>
</React.Fragment>
