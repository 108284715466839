import * as auth0 from 'auth0-js'

export default new auth0.WebAuth({
    domain: 'auth.hadean.com',
    clientID: 'aGgITA0OmCmz7Xzl6FX7RMQy1bAwjDLJ',
    responseType: 'token id_token',
    scope: 'openid profile email',
    audience: 'https://portal.hadean.com/api',
    redirectUri: window.location.origin,
    leeway: 60
})