import React from 'react'
import { Button, FormGroup, Label, Input } from 'reactstrap'
import { FormSection, PageHeading } from '../theme/PageSection'
import { postUser } from '../../api'
import { RouteComponentProps } from 'react-router-dom'
import Form from '../Form'

type State = {
    email: string
}

type Props = RouteComponentProps<{ companyKey: string }>

export default class Invite extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            email: ''
        }
    }
    submit() {
        const { companyKey } = this.props.match.params
        const { email } = this.state
        return postUser(companyKey, email)
    }
    render() {
        const { email } = this.state

        return <React.Fragment>
            <PageHeading>Invite to team</PageHeading>
            <FormSection>
                <Form onSubmit={this.submit.bind(this)}
                    successMessage="We've invited that person.">

                    <FormGroup>
                        <Label>Email address</Label>
                        <Input type="email" name="email" required
                            value={email}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => this.setState({ email: e.currentTarget.value })} />
                    </FormGroup>

                    <Button type="submit">Invite</Button>
                    
                </Form>

            </FormSection>
        </React.Fragment>
    }
}