import superagent from 'superagent'
import store from './store'

const url = (parts: TemplateStringsArray, ...args: any[]) =>
    parts.map((p, i) => p + encodeURIComponent(args[i] || '')).join('')

const auth = () => {
    const { identity } = store.getState().auth
    if (identity == null) throw new Error("User not logged in")

    return { Authorization: `Bearer ${identity.accessToken}` }
}

export type LicenseInfo = {
    aether?: boolean,
    muxer?: boolean,
    platform?: boolean
}

export const postCompany = async (companyRequest:
    {
        name: string,
        contact: { email: string }
        licenses: LicenseInfo
    }) => {
    return superagent.post("/api/companies")
        .set(auth())
        .send(companyRequest)
}

export type User = {
    name: string,
    email: string,
    roles: { admin: boolean }
}

export type Cluster = {
    key: string,
    name: string,
    creationDate: Date,
    aetherEngineVersion: string,
    state: "Ready" | "Provisioning" | "Configuring" | "ConfiguringFailed" | "ProvisioningFailed",
    logs: string,
    cloudProvider: "aws" | "azure"
}

export type ClusterDetail = Cluster & {
    users: { key: string }[]
}

export type CloudProvider = {
    id: "aws" | "azure",
    logo: string,
    logoSmall: string,
    shortDisplayName: string,
    displayName: string,
    homepage: string
}

export type Company = {
    key: string,
    name: string,
    licenses: LicenseInfo,
    clusters: Cluster[],
    users: User[],
    subscription: { endDate: string } | null
}

export type CompanySummary = {
    key: string
    name: string
}

export type PersonalAccountSummary = {
    key: string
    username: string
    created: Date
}

export type PlatformClusterSummary = {
    key: string
    name: string
    user: string | null
    companyKey: string | null
    status: "Created" | "Ready" | "Provisioning" | "Failed"
    created: Date
    sdkVersion: string
    cloudProvider: "aws" | "azure"
}

export const postUser = async (companyKey: string, email: string) => {
    return superagent.post(url`/api/companies/${companyKey}/members`)
        .set(auth())
        .send({ email })
}

export const deleteUser = async (companyKey: string, key: string) => {
    return superagent.delete(url`/api/companies/${companyKey}/members?email=${key}`)
        .set(auth())
}

export const updateUser = async (companyKey: string, key: string, patch: { roles?: User["roles"] }) => {
    return superagent.patch(url`/api/companies/${companyKey}/members?email=${key}`)
        .set(auth())
        .send(patch)
}

export const listPersonalAccounts = async (): Promise<PersonalAccountSummary[]> => {
    return superagent.get("/api/personal-accounts")
        .set(auth())
        .then(r => r.body)
}

export const postPersonalAccount = async (personalAccountRequest:
    {
        username: string
    }) => {
    return superagent.post("/api/personal-accounts")
        .set(auth())
        .send(personalAccountRequest)
}

export const deletePersonalAccount = async (key: string) => {
    return superagent.delete(url`/api/personal-accounts/${key}`)
        .set(auth())
}

export const fetchCompany = async (key: string): Promise<Company> => {
    return superagent.get(url`/api/companies/${key}`)
        .set(auth())
        .then(r => r.body)
}

export const updateCompany = async (key: string, patch: Partial<Company>): Promise<Company> => {
    return superagent.patch(url`/api/companies/${key}`)
        .set(auth())
        .send(patch)
        .then(r => r.body)
}

export const listCompanies = async (): Promise<CompanySummary[]> => {
    return superagent.get('/api/companies')
        .set(auth())
        .then(r => r.body)
}

export const fetchCluster = async (key: string): Promise<ClusterDetail> => {
    return superagent.get(url`/api/clusters/${key}`)
        .set(auth())
        .then(r => r.body)
}

export const postCluster = async (companyKey: string, name: string, aetherEngineVersion: string, bundle: Blob) => {
    return superagent.post(url`/api/companies/${companyKey}/clusters/bundles`)
        .set(auth())
        .field({ name, aetherEngineVersion })
        .attach('bundle', bundle)
}

export const patchCluster = async (key: string, patch: Partial<ClusterDetail>) => {
    return superagent.patch(url`/api/clusters/${key}`)
        .set(auth())
        .send(patch)
}

export const listCompanyPlatformClusters = async (companyKey: string): Promise<PlatformClusterSummary[]> => {
    return superagent.get(url`/api/companies/${companyKey}/platform-clusters`)
        .set(auth())
        .then(r => r.body)
}

export type UserRoles = {
    isSupportUser: boolean,
    personalAccount: boolean,
    companies: {
        [key: string]: { admin: boolean }
    }
}
export const fetchCurrentUser = async (): Promise<UserRoles> => {
    return superagent
        .get(url`/api/users/current`)
        .set(auth())
        .then((r: superagent.Response) => r.body)
}

export type Log = {
    entries: {
        timestamp: string,
        details: {
            actor: string,
            summary: string
        }
    }[]
}
export const fetchLog = async (companyKey: string): Promise<Log> => {
    return superagent
        .get(url`/api/companies/${companyKey}/log`)
        .set(auth())
        .then((r: superagent.Response) => r.body)
}

export const getAetherSdkDownloadUrl = async () => {
    return superagent
        .get("/api/downloads/sdk")
        .set(auth())
        .then((r: superagent.Response) => r.text)
}

export const getPlatformSdkDownloadUrl = async () => {
    return superagent
        .get("/api/downloads/platform-sdk")
        .set(auth())
        .then((r: superagent.Response) => r.text)
}

export const getStagedPlatformSdkDownloadUrl = async (container: string, version: string, filename: string) => {
    return superagent
        .get(`/api/downloads/staged-sdk/?container=${container}&version=${version}&filename=${filename}`)
        .set(auth())
        .then((r: superagent.Response) => r.text)
}

export const getTerraformScriptDownloadUrl = async (cloudProvider: string) => {
    return superagent
        .get(`/api/downloads/provision-cluster-bundle/?cloud=${cloudProvider}`)
        .set(auth())
        .then((r: superagent.Response) => r.text)
}

export type MetricResult = {
    total: number,
    data: { time: string, value: number }[]
}

export const fetchBandwidthMetrics = async (companyKey: string, clusterKey: string): Promise<MetricResult> => {
    return superagent
        .get(`api/companies/${companyKey}/metrics/bandwidth?clusterKey=${clusterKey}`)
        .set(auth())
        .then((r: superagent.Response) => r.body)
}

type Versions = {
    version: string,
    isBeta: boolean
}

type ClusterVersions = {
    versions: Array<Versions>
}

export const getClusterVersions = async (): Promise<ClusterVersions> => {
    return superagent
        .get(`api/downloads/aether-engine/versions`)
        .set(auth())
        .then((r: superagent.Response) => r.body)
}

type PlatformSdkVersions = {
    versions: Array<string>,
    container: string,
    filename: string
}

export const getSdkVersions = async (manifest: string): Promise<PlatformSdkVersions> => {
    return superagent
        .get(`api/downloads/platform-sdk/versions?manifest=${manifest}`)
        .set(auth())
        .then((r: superagent.Response) => r.body)
}

export const getSdkVersionsDescending = async (manifest: string): Promise<PlatformSdkVersions> =>
    getSdkVersions(manifest).then((r:PlatformSdkVersions) => {
        r.versions.reverse();
        return r;
    })
