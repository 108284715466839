import styled from 'styled-components';
import colours from './colours';


export default styled.div`
    display:inline-block;
    position:relative;
    font-weight: 600;
    font-size: .8rem;
    margin:0;
    text-transform: uppercase;
    color:white;
    padding:0 .6em;
    z-index: 2;
    :after {
        content:'';
        background: ${colours.Orange};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position:absolute;
        z-index: -1;
    }
    &.danger:after {
        background: var(--danger);
    }
    &.success:after {
        background: var(--success);
    }
`