
import React from 'react'
import { PageHeading } from '../theme/PageSection'
import { CompanyLoaderProps, withCompanyLoader } from './CompanyLoader'
import { Link } from 'react-router-dom'
import { RequireAdmin } from '../auth/RequireUser'
import Log from './Log'
import ProjectList from './ProjectList'
import UserList from './UserList'
import License from './License'
import Subscription from './Subscription'


export default withCompanyLoader((props: CompanyLoaderProps) => {
    const Extras = <>
        {(props.company.licenses.aether || props.company.licenses.platform)
            ? <Link className="btn btn-secondary btn-sm" id="sdk-downloads" to={{pathname: `/profile/${props.companyKey}/sdk-downloads`, state:{} }} style={{ marginRight: '15px' }}>&#129095; SDK Downloads</Link>
            : null }
        <RequireAdmin>
            <Link className="btn btn-secondary btn-sm" id="edit-company" to={`/profile/${props.companyKey}/edit`}>Edit company</Link>
        </RequireAdmin>
    </>

    return <React.Fragment>
        <PageHeading extras={Extras}>{props.company.name}</PageHeading>
        <ProjectList {...props} />
        <UserList {...props} />
        <Log {...props} />
        <License {...props.company} />
        <Subscription {...props} />
    </React.Fragment>
})
