import React, { useState } from 'react'
import DashboardMetric from '../dashboard/Metric'
import DashboardChart from '../dashboard/Chart'
import * as api from '../../api'
import { Card, CardBody, CardTitle, CardText } from 'reactstrap'
import { LoadingState, useLoadingState } from '../LoadingState'
import styled from 'styled-components'
import { CompanyLoaderProps } from './CompanyLoader'
import { SectionHeading, SubFullWidthSection } from '../theme/PageSection'
import { Link } from 'react-router-dom'
import { RequireAdmin } from '../auth/RequireUser'
import Badge from '../theme/Badge'
import Tab from '../theme/Tab'
import ClusterContextMenu from './ClusterContextMenu'
import { CloudProviders } from '../../store/types'

const ClusterCard = styled(Card)`
    border: 0;
    margin-bottom: 4px !important;
    background: #f0f0f0;
`;

const GridBody = styled(CardBody)`
    display:grid;
    grid-auto-flow:row;
    grid-template-columns:3fr 2fr 1fr min-content;
    grid-column-gap:1em;
`;

const LogsBadge = styled(Badge as any)`
    left: -1px;
    text-transform: none;
    &:after {
        background-color: white;
    }
`;

const Key = styled.code`
    color: black;
`;

const ProviderLogo = styled.img`
    width: 25px;
    height: 25px
`

const ClusterTitle = styled.h6`
    color: black;
`


function formatBandwidthMetric(bandwidthMetric: number): string {
    return `${(((bandwidthMetric / 1024) / 1024) / 1024).toFixed(2)} GB`
}

function formatCreationDate(date: Date): string {
    var formatted = "N/A";
    if (date != null) {
        let creationDate = new Date(date);
        if (creationDate >= new Date(2000, 1, 1)) {
            formatted = creationDate.toLocaleDateString();
        }
    }
    return formatted;
}

function formatAetherEngineVersion(cluster: api.Cluster): string {
    var formatted = "Unknown Version";
    if (cluster.aetherEngineVersion != null) {
        formatted =  `Aether SDK version: ${cluster.aetherEngineVersion}`
    }
    return formatted;
}

function formatPlatformSdkVersion(version: string): string {
    if (version != null) {
        return `SDK: ${version}`
    }
    return "Unknown Version";
}

const Cluster = ({ cluster, companyKey }: { cluster: api.Cluster, companyKey: string }) => {
    const { state, result: bandwidthMetric } = useLoadingState(api.fetchBandwidthMetrics, companyKey, cluster.key)

    const provider = cluster.cloudProvider && CloudProviders.find(c => c.id.toLowerCase() === cluster.cloudProvider.toLowerCase())

    return <ClusterCard className="mb-3">
        <GridBody>
            <div>
                <CardTitle>
                    <ClusterTitle>
                        {cluster.name}
                    </ClusterTitle>
                    {/* TODO: we should separate these for better UX notification - this requires changes to HDNE */}
                    {["Ready"].includes(cluster.state) && <div>
                        <Badge className="success">Ready</Badge>
                    </div> }
                    {["Provisioning", "Configuring"].includes(cluster.state) && <div>
                        <Badge>Provisioning</Badge>
                    </div> }
                    {["ProvisioningFailed", "ConfiguringFailed"].includes(cluster.state) && <div>
                        <Badge className="danger">Failed to provision</Badge>
                        {cluster.logs != null && <LogsBadge><a href={cluster.logs} target="_blank" rel="noopener noreferrer">Download provisioning logs</a></LogsBadge> }
                    </div>}
                </CardTitle>
                <CardText>Key: <Key>{cluster.key}</Key></CardText>
            </div>
            <div>
                <CardText>Creation date: {formatCreationDate(cluster.creationDate)}</CardText>
                <CardText>{formatAetherEngineVersion(cluster)}</CardText>
            </div>
            <div>
                {cluster.state === "Ready" &&
                    <LoadingState state={state}>
                        {bandwidthMetric &&
                            <DashboardMetric title="Bandwidth Used" metric={formatBandwidthMetric(bandwidthMetric.total)} />
                        }
                    </LoadingState>}
            </div>

            <div>
                <ClusterContextMenu companyKey={companyKey} cluster={cluster} />
                {provider &&
                    <a href={provider.homepage} target="_blank" rel="noopener noreferrer nofollow">
                    <ProviderLogo style={{ marginTop: '4px' }} src={provider.logoSmall}/>
                    </a>
                }
            </div>

            {cluster.state === "Ready" && bandwidthMetric &&
                <div style={{ gridRow: "2", gridColumn: "1/3" }}>
                    <DashboardChart data={bandwidthMetric.data} />
                </div>
            }
        </GridBody>
    </ClusterCard>
}

function companyClusters(company: api.Company) {
    return company.clusters.length ?
        company.clusters.map(cluster => <Cluster cluster={cluster} key={cluster.key} companyKey={company.key} />)
        : <div><p /> <p>You don't have any Aether projects yet. Contact us to create one.</p></div>
}

const Create = (companyKey: string) => <RequireAdmin>
    <Link className="btn btn-secondary btn-sm" id="create-project" to={`/profile/${companyKey}/new-project`}>Create project</Link>
</RequireAdmin>

const tabNames = ["Aether Engine", "Hadean Platform"];

function TabGroup(props: CompanyLoaderProps) {
    const { state, result: platClustList } = useLoadingState(api.listCompanyPlatformClusters, props.companyKey)
    const [active, setActive] = useState(tabNames[0]);

    return (
        <LoadingState state={state}>
            <div>
                {tabNames.map((name) => (
                    <Tab key={name} active={active === name} onClick={() => setActive(name)}>
                        {name}
                    </Tab>
                ))}
            </div>
            {active.includes('Aether') ? companyClusters(props.company) : platformClusters(platClustList)}
        </LoadingState>
    );
}

const Page = (props: CompanyLoaderProps) => <>
    <SectionHeading extras={props.company.licenses.aether ? Create(props.companyKey) : null}>Projects</SectionHeading>
    <SubFullWidthSection>
        <div id="project-list">
            <TabGroup {...props} />
        </div>
    </SubFullWidthSection>
</>

export default Page

const PlatformClusterList = ({ platClust }: { platClust: api.PlatformClusterSummary }) => {
    const provider = platClust.cloudProvider && CloudProviders.find(c => c.id.toLowerCase() === platClust.cloudProvider.toLowerCase())

    return <ClusterCard className="mb-3">
        <GridBody>
            <div>
                <CardTitle>
                    <ClusterTitle>
                        {platClust.name}
                    </ClusterTitle>
                    {["Ready"].includes(platClust.status) && <div>
                        <Badge className="success">Ready</Badge>
                    </div>}
                    {["Created", "Provisioning"].includes(platClust.status) && <div>
                        <Badge>{platClust.status}</Badge>
                    </div>}
                    {["Failed"].includes(platClust.status) && <div>
                        <Badge className="danger">Failed to provision</Badge>
                    </div>}
                </CardTitle>
            </div>
            <div />
            <div>
                <CardText style={{ textAlign: 'right' }}>Created: {formatCreationDate(platClust.created)}</CardText>
                <CardText style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{formatPlatformSdkVersion(platClust.sdkVersion)}</CardText>
                <div style={{ textAlign: 'right' }}>
                    {provider &&
                        <a href={provider.homepage} target="_blank" rel="noopener noreferrer nofollow">
                            <ProviderLogo src={provider.logoSmall} />
                        </a>
                    }
                </div>
            </div>
        </GridBody>
    </ClusterCard>
}

function platformClusters(clusters: api.PlatformClusterSummary[] | null) {
    return clusters && clusters.length ?
        clusters.map(pcs => <PlatformClusterList platClust={pcs} key={pcs.key} />)
        : <div><p /> <p>You don't have any Platform clusters.</p></div>
}
