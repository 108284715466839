import React from 'react'
import { Button, FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import { FormSection, PageHeading } from '../theme/PageSection'
import { postCluster, getClusterVersions, getTerraformScriptDownloadUrl, CloudProvider } from '../../api'
import { RouteComponentProps } from 'react-router-dom'
import Form from '../Form'
import { ResponseError } from 'superagent'
import { DispatchProp, connect } from 'react-redux'
import { invalidateCompany } from '../../store/companyProfile'
import { CloudProviders, getCloudProvider } from '../../store/types';
import { TextAnchor } from '../theme/TextLink'
import { LoadingState, useLoadingState } from '../LoadingState'
import styled from 'styled-components'


type Props = RouteComponentProps<{ companyKey: string }> & DispatchProp<any>
type ClusterVersionProps = { setVersion: (version: string) => void }

const CloudSelectInput = styled(Input)`
    padding-left: 40px;
`;

const ProviderLogo = styled.div`
        position: absolute;
        margin-top: 15px;
        margin-left: 8px;
`;

const ClusterVersions = ({ setVersion }: ClusterVersionProps) => {
    const { state, result } = useLoadingState(getClusterVersions)

    React.useEffect(() => {
        if (result) {
            result.versions.sort((a, b) => b.version.localeCompare(a.version));
            setVersion(result.versions[0].version);
        }
    }, [result])

    return <LoadingState state={state}>
        <Input
            type="select"
            name="aetherEngineVersion"
            required
            onChange={(e: React.FormEvent<HTMLInputElement>) => setVersion(e.currentTarget.value)}>
                {
                    result && result.versions.map((val, index) => {
                        return (
                            <option
                                key={index}
                                value={val.version}
                            >{
                                val.isBeta
                                ? `${val.version}-beta`
                                : val.version
                            }</option>
                        )
                    })
                }
        </Input>
    </LoadingState>
}

const NewProject = (props: Props) => {
    const [name, setName] = React.useState('')
    const [version, setVersion] = React.useState<string>("")
    const [cloudProvider, setCloudProvider] = React.useState<CloudProvider>(getCloudProvider())
    const [bundle, setBundle] = React.useState<File | null>(null)
    const [status, setStatus] = React.useState(0)
    const { companyKey } = props.match.params

    const submit = async () => {
        if (!name || !bundle) throw new Error("Required fields not supplied")
        await postCluster(companyKey, name, version, bundle)
        props.dispatch(invalidateCompany())
    }

    const onError = (e : ResponseError) => {
        setStatus(e.status || 0)
        return true
    }

    const download = async () => {
        window.location.href = await getTerraformScriptDownloadUrl(cloudProvider.id)
    }



    return <React.Fragment>
        <PageHeading>Create a new project</PageHeading>
        <FormSection>
            <p>
                Create a new project in your own cloud by uploading a configuration bundle file. <br />
            </p>
            <Label>Cloud Provider</Label>
            <div>
                <ProviderLogo >
                    <img src={cloudProvider.logoSmall} height="30px" width="30px" />
                </ProviderLogo>
                <CloudSelectInput
                    id="platform-sdk-flavour"
                    type="select"
                    name="cloudProvider"
                    required
                    onChange={(e: React.FormEvent<HTMLInputElement>) => setCloudProvider(getCloudProvider(e.currentTarget.value))}>
                    {
                        CloudProviders.map((val: any, index: any) => {
                            return (
                                <option
                                    key={index}
                                    value={val.id}
                                    defaultChecked={val.default}
                                >{val.displayName}</option>
                            )
                        })
                    }
                </CloudSelectInput>
             </div>
            <Button id="download-sdk-link" onClick={download} className="btn-sm" style={{ marginRight: "15px", marginTop: "15px", marginBottom: "15px" }}>🡇 Download {cloudProvider.shortDisplayName} Cluster Provision Bundle</Button> <br />
            <TextAnchor href="https://docs.hadean.com/aether-engine/get-started/managing-deployment-targets" target="_blank">Instructions for provisioning cluster</TextAnchor>
            <Form onSubmit={submit}
                onError={onError}
                redirect={`/profile/${companyKey}`}>

                <FormGroup>
                    <Label>Project name</Label>
                    <Input type="text" name="name" required
                        value={name}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => setName(e.currentTarget.value)} />
                </FormGroup>

                <FormGroup>
                    <Label>Aether Engine version</Label>
                    <ClusterVersions setVersion={setVersion} />
                </FormGroup>

                <FormGroup>
                    <Label>Project bundle</Label>
                    <Input type="file" name="bundle" required
                        invalid={status === 409}
                        onChange={
                            (e: React.FormEvent<HTMLInputElement>) =>
                                e.currentTarget.files && e.currentTarget.files.length && setBundle(e.currentTarget.files[0])
                        } />
                    <FormFeedback id="cluster-conflict">Looks like you've already uploaded that bundle file.</FormFeedback>

                </FormGroup>

                <Button type="submit">Create</Button>
            </Form>

        </FormSection>
    </React.Fragment>
}

export default connect()(NewProject)