import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom'
import MustBeLoggedIn from './components/auth/MustBeLoggedIn'
import requireAdmin from './components/auth/RequireUser'
import NewPersonalAccount from './components/support/NewPersonalAccount'
import PlatformSDKDownload from './components/platform-sdk/PlatformSDKDownload'
import Layout from './components/Layout'

const PlatformSDKDownloads = () => {
    let { path }: any = useRouteMatch()

    return (
        <MustBeLoggedIn>
            <Layout>
                <Switch>
                    <Route exact path={`${path}`} component={PlatformSDKDownload} />
                </Switch>
            </Layout>
        </MustBeLoggedIn>
    )
}

export default PlatformSDKDownloads