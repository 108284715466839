import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom'
import MustBeLoggedIn from './components/auth/MustBeLoggedIn'
import requireAdmin from './components/auth/RequireUser'
import NewPersonalAccount from './components/support/NewPersonalAccount'
import PersonalAccountList from './components/support/PersonalAccountList'
import Layout from './components/Layout'

const PersonalAccount = () => {
    let { path }: any = useRouteMatch()

    return (
        <MustBeLoggedIn>
            <Layout>
                <Switch>
                    <Route exact path={`${path}`} component={PersonalAccountList} />
                    <Route exact path={`${path}/new`} component={NewPersonalAccount} />
                </Switch>
            </Layout>
        </MustBeLoggedIn>
    )
}

export default requireAdmin(PersonalAccount)