import React from 'react'
import * as api from '../../api'
import Table from '../theme/Table'
import { CompanyLoaderProps } from './CompanyLoader'
import { DropdownItem } from 'reactstrap'
import ContextMenu from '../theme/ContextMenu'
import { removeUser, updateUser } from '../../store/companyProfile'
import Confirmation from '../Confirmation'
import { connect, DispatchProp } from 'react-redux'
import { ApplicationState } from '../../store'
import Badge from '../theme/Badge'
import requireUser from '../auth/RequireUser'
import { SectionHeading, SubFullWidthSection } from '../theme/PageSection'
import { Link } from 'react-router-dom'
import { RequireAdmin } from '../auth/RequireUser'


type UserListProps = CompanyLoaderProps & { identity: string }

const UserContextMenu = connect(
    (state: ApplicationState) => ({
        currentUser: state.auth!.userKey,
        admin: !!state.user && state.user.admin
    })
)(
    (props: { user: api.User, currentUser: string | null, admin: boolean } & DispatchProp<any>) => {
        const [showConfirmation, setShowConfirmation] = React.useState(false)
        const { user, dispatch } = props
        const isCurrentUser = props.currentUser === user.email
        if (!props.admin || isCurrentUser) return null;

        const setAdmin = (isAdmin: boolean) =>
            dispatch(updateUser({ ...user, roles: { admin: isAdmin } }))

        return <React.Fragment>
            <Confirmation confirmText="Remove"
                show={showConfirmation}
                onClose={() => setShowConfirmation(false)}
                onConfirm={() => dispatch(removeUser(user))}>
                Are you sure you want to remove {user.name || "this user"}?
            </Confirmation>

            <ContextMenu className="t-user-menu">
                <DropdownItem
                    className="text-danger t-remove-user"
                    onClick={() => setShowConfirmation(true)}>Remove</DropdownItem>

                {!user.roles.admin && <DropdownItem
                    className="t-make-admin"
                    onClick={() => setAdmin(true)}>Make admin</DropdownItem>}

                {user.roles.admin && <DropdownItem
                    className="t-revoke-admin"
                    onClick={() => setAdmin(false)}>Revoke admin role</DropdownItem>}
            </ContextMenu>
        </React.Fragment>
    })

const UserTable = ({ company }: UserListProps) => <Table id="user-list">
    <colgroup>
        <col />
        <col />
        <col className="min" />
    </colgroup>
    <thead>
        <tr><th>Name</th><th>Email</th><th></th></tr>
    </thead>
    <tbody>
        {company.users.map((user: api.User) => <tr key={user.email}>
            <td>
                {user.name || "—"}
                {" "}
                {user.roles && user.roles.admin && <Badge>Admin</Badge>}
            </td>
            <td>{user.email}</td>
            <td><UserContextMenu user={user} /></td>
        </tr>
        )}
    </tbody>
</Table>

const Invite = (companyKey: string) => <RequireAdmin>
    <Link className="btn btn-secondary btn-sm" id="invite-user" to={`./${companyKey}/invite`}>Invite to company</Link>
</RequireAdmin>

const Page = (props: UserListProps) => <>
    <SectionHeading extras={Invite(props.companyKey)}>Users</SectionHeading>
    <SubFullWidthSection>
        <UserTable {...props} />
    </SubFullWidthSection>
</>

export default requireUser(Page)