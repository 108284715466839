import * as React from 'react'
import { Route } from 'react-router-dom'
import Home from './components/Home'
import ProfileRoute from './ProfileRoute'
import { connect } from 'react-redux'
import CompaniesRoute from './CompaniesRoute'
import PersonalAccountsRoute from './PersonalAccountsRoute'
import PlatformSDKDownloadsRoute from './PlatformSDKDownloadsRoute'
import { initialise } from './store/Auth'
import { Switch } from 'react-router'
import AuthRoute from './AuthRoute'


const App = (props: { initialise: () => void }) => {
    React.useEffect(props.initialise, [props])

    return <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/auth' component={AuthRoute} />
        <Route path='/companies' component={CompaniesRoute} />
        <Route path='/personal-accounts' component={PersonalAccountsRoute} />
        <Route path='/platform-sdk-downloads' component={PlatformSDKDownloadsRoute} />
        <Route path='/profile' component={ProfileRoute} />
    </Switch>
}

export default connect(null, { initialise })(App)
