import colours from '../theme/colours'
import styled from 'styled-components'

export default styled.p`
    margin-top: 15px;
    font-size:0.8rem;
    color: ${colours.Indigo};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-align: center;
    ::before, ::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid ${colours.Stone};
    }
    ::before {
        margin-right: .25em;
    }
    ::after {
        margin-left: .25em;
    }
`