import React from 'react'
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap"

type ConfirmationProps = {
    show: boolean,
    confirmText: string,
    onConfirm: () => void,
    onClose: () => void
    children: any
}

export default (props: ConfirmationProps) => {
    const confirm = () => {
        props.onClose()
        props.onConfirm()
    }
    return <Modal isOpen={props.show}>
        <ModalBody>{props.children}</ModalBody>
        <ModalFooter>
            <Button id="confirm-yes" color="primary" onClick={confirm}>{props.confirmText}</Button>{' '}
            <Button id="confirm-no" color="secondary" onClick={props.onClose}>Cancel</Button>
        </ModalFooter>
    </Modal>
}
