import { ClusterDetail, Company } from "../../api"
import React from "react"
import { SectionHeading, SubFullWidthSection } from "../theme/PageSection"
import UserMultiselect from "./UserMultiselect"

type Props = {
    cluster: ClusterDetail,
    company: Company,
    onChange: (users: ClusterDetail["users"]) => void
}

export default ({ cluster, company, onChange }: Props) => {
    const [users, setUsers] = React.useState(cluster.users.map(u => u.key))
    const handleOnChange = (users: string[]) => {
        setUsers(users)
        onChange(users.map(key => ({ key })))
    }
    return <>
        <SectionHeading>Manage access</SectionHeading>
        <SubFullWidthSection id="manage-access">
            <UserMultiselect
                company={company}
                selected={users}
                onChange={handleOnChange}
                adminsPreselected={true}
            />
        </SubFullWidthSection>
    </>
}