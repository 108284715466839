export default {
    brandDark: '#FF5C37',
    brandMid: '#f3a712',
    brandLight: "#FF9448",
    border: '#cacaca',
    textDark: '#27203B',
    textMid: '#61616a',
    textLight: '#848484',
    Indigo: '#27203B',
    Indigo80: '#27203B80',
    Indigo60: '#27203B60',
    Indigo40: '#27203B40',
    Indigo20: '#27203B20',
    Coral: '#FF5C37',
    Coral80: '#FF5C3780',
    Coral60: '#FF5C3760',
    Coral40: '#FF5C3740',
    Coral20: '#FF5C3720',
    Orange: '#FF9448',
    Orange80: '#FF944880',
    Orange60: '#FF944860',
    Orange40: '#FF944840',
    Orange20: '#FF944820',
    Copper: '#D66445',
    Copper80: '#D6644580',
    Copper60: '#D6644560',
    Copper40: '#D6644540',
    Copper20: '#D6644520',
    Stone: '#F6F4F2',
    White: '#FFFFFF',
    Black: '#000000'
}