import React from 'react'
import { FormGroup, Label, Input, Button } from 'reactstrap'
import { FormSection, PageHeading } from '../theme/PageSection'
import Form from '../Form'
import { updateCompany, Company } from '../../api'
import { CompanyLoaderProps, withCompanyFromRoute } from './CompanyLoader'
import { RequireSupport } from '../auth/RequireUser'
import License, { ProductFlagPanel, ProductNameAether, ProductNamePlatform } from './License'


export default withCompanyFromRoute((props: CompanyLoaderProps) => {
    const [patch, setPatch] = React.useState<Partial<Company>>({})
    const [name, setNameState] = React.useState(props.company.name)

    const [aether, setAetherState] = React.useState(props.company.licenses.aether)
    const [platform, setPlatformState] = React.useState(props.company.licenses.platform)

    const yyyymmdd = props.company.subscription ?
        new Date(props.company.subscription.endDate).toISOString().split('T')[0] :
        ""

    const [endDate, setEndDateState] = React.useState(yyyymmdd)

    const setName = (v: string) => {
        setPatch({ ...patch, name: v })
        setNameState(v)
    }

    const setAether = (v: boolean) => {
        setPatch({ ...patch, licenses: { ...patch.licenses, aether: v } })
        setAetherState(v)
    }

    const setPlatform = (v: boolean) => {
        setPatch({ ...patch, licenses: { ...patch.licenses, platform: v } })
        setPlatformState(v)
    }

    const setEndDate = (v: string) => {
        setPatch({ ...patch, subscription: { endDate: v } })
        setEndDateState(v)
    }

    const removeSub = () => {
        setPatch({ ...patch, subscription: null })
        setEndDateState("")
    }

    const submit = () => updateCompany(props.companyKey, patch)
        .then((result: Company) => props.dispatch({ type: 'COMPANY_RECEIVED', payload: result }));

    return <React.Fragment>
        <PageHeading>{props.company.name}</PageHeading>
        <FormSection>
            <Form onSubmit={submit} redirect={`/profile/${props.companyKey}`}>

                <FormGroup>
                    <Label>Company name</Label>
                    <Input type="text" name="name" required
                        value={name}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => setName(e.currentTarget.value)} />
                </FormGroup>

                <RequireSupport>
                    <FormGroup>
                    <div>
                        <legend>Licensed products</legend>
                        <ProductFlagPanel data-testid="product-flags">
                            <div><Label> <Input type="checkbox" name="aether" checked={aether}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setAether(e.currentTarget.checked)} />
                                {ProductNameAether}</Label></div>
                            <div><Label> <Input type="checkbox" name="platform" checked={platform}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setPlatform(e.currentTarget.checked)} />
                                {ProductNamePlatform}</Label></div>
                         </ProductFlagPanel>
                    </div>
                    </FormGroup>
                    <fieldset className="form-group">
                        <legend>Subscription</legend>
                        <p>
                            <Label>End date</Label>
                            <Input type="date" name="endDate"
                                value={endDate}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setEndDate(e.currentTarget.value)} />
                        </p>
                        <p><Button id="remove-subscription" size="sm" onClick={removeSub}>Deactivate subscription</Button></p>
                    </fieldset>
                </RequireSupport>

                <Button type="submit">Save</Button >
            </Form>

        </FormSection>
    </React.Fragment>
})