import * as React from 'react'
import styled from 'styled-components'
import colours from './theme/colours'

const Footer = styled.footer`
    font-size: .7rem;
    padding:1em;
    color: ${colours.Indigo80};
    display:flex;
    div:first-child { 
        flex:1;
    }
    a { 
        color: ${colours.Indigo80};
    }
`

export default () => <Footer className="container">
    <div>Copyright © {new Date().getFullYear()} Hadean Supercomputing Ltd. All Rights Reserved.</div>
    <div><a href="https://hadean.com/terms/privacy-policy/">Privacy</a></div>
</Footer>