import React, { useEffect, useRef, useState } from 'react'
import { RequireSupport } from '../auth/RequireUser'
import { CompanyLoaderProps, withCompanyFromRoute, withCompanyLoader } from './CompanyLoader'
import Tab from '../theme/Tab'
import { PageHeading, SectionHeading, WideSection } from '../theme/PageSection';
import { getAetherSdkDownloadUrl, getSdkVersionsDescending, getStagedPlatformSdkDownloadUrl } from '../../api'
import { Button, Input } from 'reactstrap';
import styled from 'styled-components';
import linuxLogo from '../../theme/linux-tux.svg'
import { CopyBlock, dracula } from 'react-code-blocks';
import { LoadingState, useLoadingState } from '../LoadingState';
import { Redirect, useLocation } from 'react-router';

const tabNames = ["Aether Engine", "Hadean Platform"];

export default (props: CompanyLoaderProps) => {
    const locn = useLocation();
    const startTab = props.company.licenses.aether? 0 : 1;
    const [active, setActive] = useState(tabNames[startTab]);

    if (!locn.state) return <Redirect to="/" />; // Prevents access by direct url manip (need token from link).

    return <WideSection>
        <PageHeading>SDK Downloads</PageHeading>
        {tabNames.map((name) => (
            <Tab key={name}
                    disabled={name.includes('Aether') ? !props.company.licenses.aether : !props.company.licenses.platform}
                    active={active === name}
                    onClick={() => setActive(name)}>
                {name}
            </Tab>
        ))}
        {active.includes('Aether') ? aetherDownload() : null}
        {PlatformDownload(active.includes('Hadean'))}
    </WideSection>
}

const downloadAetherSDK = async () => {
    window.location.href = await getAetherSdkDownloadUrl()
}

function aetherDownload() {
    return <>
        <SectionHeading>Download Aether Engine SDK</SectionHeading>
        <p>Giant scale spatial simulations.</p>
        <p>Below is the download for the Aether Engine SDK installer. On download you will be able to run the Windows installer which includes version selection. Additionally you can run a demo as shown in the <a href="https://docs.hadean.com/aether-engine" rel="noopener noreferrer nofollow" target="_blank">documentation</a>.</p>
        <Button id="download-aether-sdk-link" onClick={downloadAetherSDK} className="btn-sm" style={{ marginRight: "15px" }}>&#129095; Download Aether Engine SDK</Button>
    </>
}

const PlatformContainer = styled.div`
    display: flex;
`

const PlatformLogo = styled.div`
    align-self: end;
`

const PlatformContent = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
`

const PlatformDownloadCode = styled.div`
    max-width: 700px;
`

const InlinePre = styled.span`
    display: inline;
`

const ManifestNames = ["Production", "Development", "ReleaseCandidate"]
type ManifestFunc = { setManifest: (name: string) => void }
type PlatformVersionFunc = { setVer: (version: string) => void }

function PlatformDownload(draw: boolean) {
    const [manifest, setManifest] = React.useState<string>(ManifestNames[0]);
    const [downloadLink, setDownloadLink] = useState<string | undefined>(undefined);
    const [selectedVersion, setSelectedVersion] = React.useState<string>("");

    let container = useRef<string>("");
    let filename = useRef<string>("");

    useEffect(() => {
        (async () => {
            if (container && selectedVersion && filename) {
                const link = await getStagedPlatformSdkDownloadUrl(container.current, selectedVersion, filename.current)
                setDownloadLink(link)
            }
        })()
    }, [selectedVersion])

    if (!draw) return null; // Prevent error with conditional render of useEffect.

    const LinuxDownload = <PlatformContainer>
        <PlatformLogo>
            <img src={linuxLogo} height="100px" width="100px" />
        </PlatformLogo>
        <PlatformContent>
            <div>
                <h2>Linux</h2>
            </div>
            <PlatformDownloadCode>
                <CopyBlock
                    text={`wget '${downloadLink}' -qO - | tar xz && hadean-platform-sdk/install.sh && rm -rf hadean-platform-sdk`}
                    theme={dracula}
                    language="bash"
                    showLineNumbers={false}
                    wrapLines
                    codeBlock
                />
            </PlatformDownloadCode>
        </PlatformContent>
    </PlatformContainer>

    const ManifestSelect = ({ setManifest }: ManifestFunc) => {
        return <PlatformContainer>
            <div style={{ width: "200px" }}>
                <p />
                <label>Staged SDK manifest</label>
            </div>
            <Input type="select"
                name="manifest"
                value={manifest}
                required
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setManifest(e.currentTarget.value);
                    setSelectedVersion("");
                }} >
                {ManifestNames.map((val, key) => <option key={key} value={val}>{val}</option>)}
            </Input>
        </PlatformContainer>
    }

    const VersionSelect = ({ setVer }: PlatformVersionFunc) => {
        const { state, result } = useLoadingState(getSdkVersionsDescending, manifest)

        // Called via useLoadingState setting state of 'result' when finished, causing a re-render.
        React.useEffect(() => {
            let cancel = false;
            if (result && !cancel) {
                container.current = result.container
                filename.current = result.filename
                if (selectedVersion == "")
                    setSelectedVersion(result.versions[0]);
            }
            return () => { cancel = true; }
        }, [result])

        return <LoadingState state={state}>
            <PlatformContainer>
            <div style={{ width: "200px" }}>
                <p />
                <label>SDK Version</label>
            </div>
            <Input
                type="select"
                name="version"
                value={selectedVersion}
                required
                onChange={(e: React.FormEvent<HTMLInputElement>) => { setSelectedVersion(e.currentTarget.value); }}>
                {
                    result && result.versions.map((val, key) =>
                        <option key={key} value={val}>{val}</option>)
                }
            </Input>
            </PlatformContainer>
        </LoadingState>
    }

    const downloadStagedSdk = async () => {
        if (selectedVersion)
            window.location.href = await getStagedPlatformSdkDownloadUrl(container.current, selectedVersion, filename.current)
    }

    const Main = <>
        <SectionHeading>Download Platform SDK</SectionHeading>
        <p>Powering dynamically scaled applications in the cloud.</p>
        <p>Below are the available downloads for the latest version of the Hadean Platform SDK.</p>
        <p>After installing, find the included examples in <InlinePre>~/hadean/examples</InlinePre>. Additionally, run a demo as shown in the <a href="https://docs.hadean.com/platform" rel="noopener noreferrer" target="_blank">documentation</a>.</p>
        <p>The commands are only valid for 10 minutes, after which you'll have to refresh this page.</p>
        <RequireSupport>
            <ManifestSelect setManifest={setManifest} />
        </RequireSupport>
        <VersionSelect setVer={setVersion} />
        <RequireSupport>
            <Button id="download-staged-sdk" onClick={downloadStagedSdk} className="btn-sm" style={{ marginRight: "15px", marginTop: "15px", marginBottom: "15px" }}>Download staged</Button>
        </RequireSupport>
        {downloadLink && LinuxDownload}
    </>

    function setVersion(val: string) {
        setSelectedVersion(val)
    }

    return <WideSection>{Main}</WideSection>
}
