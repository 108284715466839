import React from 'react'
import styled from 'styled-components'

const FormSectionInner = styled.div`
  width: 480px;
  h1 {
      font-size:2rem;
      text-align:center;
      margin:0 0 2rem 0;
  }
  button[type=submit]{
      width:100%;
  }
  &.border { 
    border:1px solid #e0e0e0;
    padding:3em;
  }
`

const Constraint = styled.div`
  display:flex;
  justify-content:center;
`

export const FormSection = ({ children, ...props }) =>
  <Constraint {...props}>
    <FormSectionInner className={true && 'border'}>{children}</ FormSectionInner>
  </Constraint>

export const FormSectionBorder = ({ children, ...props }) =>
  <Constraint {...props}>
    <FormSectionInner className='border'>{children}</ FormSectionInner>
  </Constraint>

const WideSectionInner = styled.div`
  width: 800px;
`

export const WideSection = ({ children, ...props }) =>
  <Constraint className="container" {...props}>
    <WideSectionInner>{children}</ WideSectionInner>
  </Constraint>

const SectionDivider = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`

export const FullWidthSection = ({ children, ...props }) => <SectionDivider {...props} className="mb-3">
  <div className="container">{children}</div>
</SectionDivider>

const H1 = styled.h1`
  font-weight:600;
  font-size:1.25rem;
  margin:20px 0;
`
const RightAlign = styled.div`
  margin-left:auto;
`
const Row = styled.div`
  display:flex;
  flex-direction:row;
  align-items: center;
`
export const PageHeading = ({ children, extras = null, ...props }) => <FullWidthSection {...props}>
  <Row>
    <H1>{children}</H1>
    {extras && <RightAlign>{extras}</RightAlign>}
  </Row>
</FullWidthSection>

export const SubFullWidthSection = ({ children, ...props }) => <div {...props} className="mb-3">
  <div className="container">{children}</div>
</div>

export const SectionHeading = ({ children, extras = null, ...props }) => <SubFullWidthSection>
    <Row>
      <H1>{children}</H1>
      {extras && <RightAlign>{extras}</RightAlign>}
    </Row>
  </SubFullWidthSection>