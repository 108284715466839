import styled, { ThemedStyledFunction } from 'styled-components';
import { Link } from 'react-router-dom';
const create = (tag:ThemedStyledFunction<any, any>) => tag`
    &:before {
        content:'';
        width: 0;
        height: 0;
        border-top: .3em solid transparent;
        border-left: .6em solid;
        border-bottom: .3em solid transparent;
        display:inline-block;
        opacity:.5;
        margin:0 .5em 0 0;
        transition:.25s ease all;
      }
    }

    &:hover { 
        text-decoration:none;
    }

    &:hover:before {
        opacity:.7;
        margin:0 .25em;
    }
`

export const TextLink = create(styled(Link))
export const TextAnchor = create(styled.a)
export default TextLink