import React from "react";
import { Form as StrapForm, UncontrolledAlert } from "reactstrap";
import { Redirect } from "react-router-dom";
import { LocationDescriptor } from "history";

type NetworkState = null | "complete" | "error" | "wait"

type Props = {
    onSubmit: () => Promise<any>,
    onError?: (error:any) => boolean,
    successMessage?: string,
    redirect?: LocationDescriptor<any>,
    children: any
}

export default (props: Props) => {
    const [network, setNetwork] = React.useState<NetworkState>(null)
    const [errorHandled, setErrorHandled] = React.useState(false)

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (network !== "wait") {
            setNetwork("wait")
            props.onSubmit()
                .then(() => setNetwork("complete"))
                .catch((error:any) => {
                    setNetwork("error")
                    if (props.onError) setErrorHandled(props.onError(error))
                })
        }
    }

    const { children, successMessage, redirect } = props
    return <StrapForm onSubmit={submit}>
        {network === "complete" && <React.Fragment>
            {successMessage && <UncontrolledAlert color="success" id="success-message">{successMessage}</UncontrolledAlert>}
            {redirect && <Redirect to={redirect} />}
        </React.Fragment>}

        {children}

        {network === "error" && !errorHandled && <UncontrolledAlert color="danger">There was an error. Please try later.</UncontrolledAlert>}
    </StrapForm >
} 