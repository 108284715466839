import React from 'react'
import * as api from '../../api'
import Table from '../theme/Table'
import { LoadingState, useLoadingState } from '../LoadingState'
import { SectionHeading, SubFullWidthSection } from '../theme/PageSection'

type LogProps = { companyKey: string }

const formatter = new Intl.DateTimeFormat(window.navigator.languages[0], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
})

const LogTable = (props: LogProps) => {
    const { state, result: log } = useLoadingState(api.fetchLog, props.companyKey)
    return <LoadingState state={state}>
        <Table id="log-list">
            <colgroup>
                <col style={{ width: "25%" }} />
                <col style={{ width: "25%" }} />
                <col style={{ width: "50%" }} />
            </colgroup>
            <thead>
                <tr><th>Date</th><th>User</th><th>Summary</th></tr>
            </thead>
            <tbody>
                {log && log.entries.map(entry => {
                    const date = new Date(entry.timestamp)
                    return <tr key={entry.timestamp}>
                        <td>{formatter.format(date)}</td>
                        <td>{entry.details.actor}</td>
                        <td>{entry.details.summary}</td>
                    </tr>
                })}
            </tbody>
        </Table>
    </LoadingState>
}

export default (props: any) => <>
    <SectionHeading>Logs</SectionHeading>
    <SubFullWidthSection>
        <LogTable companyKey={props.company.key} />
    </SubFullWidthSection>
</>