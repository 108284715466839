import * as React from 'react'
import Form from '../Form'
import { Label, Input, FormGroup, Button, FormFeedback } from 'reactstrap'
import { FormSectionBorder as FormSection } from '../theme/PageSection'
import webAuth0 from './auth0Config'
import { DbSignUpOptions, Auth0Error } from 'auth0-js'
import AuthLayout from './AuthLayout'

const SignUp = () => {
    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [error, setError] = React.useState<string | null>(null)

    const submitAuth0 = () => new Promise((resolve, reject) => {
        setError(null)
        webAuth0.signup({
            connection: 'Email-Password-Authentication',
            email: email,
            password: password,
            name: name
        } as DbSignUpOptions, (err) => {
            if (err) reject(err)
            else resolve()
        })
    })

    const onError = (e: Auth0Error) => {
        switch (e.code) {
            case "invalid_signup":
            case "invalid_password":
                setError(e.code)
                return true
            default: return false
        }
    }

    return <AuthLayout>
        <FormSection>

            <Form onSubmit={submitAuth0} onError={onError}
                redirect={{ pathname: "/auth/signin", state: { message: "We've sent you an email to verify your account. Please follow the instructions, and then sign in." } }}>

                <h1>Create an account</h1>

                <FormGroup>
                    <Label>Full name</Label>
                    <Input type="text" name="name" required
                        value={name}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => setName(e.currentTarget.value)} />
                </FormGroup>

                <FormGroup>
                    <Label>Your email address</Label>
                    <Input type="email" name="email" required
                        invalid={error === "invalid_signup"}
                        value={email}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)} />
                    <FormFeedback>That email address is not valid or is already in use.</FormFeedback>
                </FormGroup>

                <FormGroup>
                    <Label>Password</Label>
                    <Input type="password" name="password" required
                        invalid={error === "invalid_password"}
                        value={password}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)} />
                    <FormFeedback>Your password must contain at least 3 of the following: upper case letters, lower case letters, numbers and symbols.</FormFeedback>
                </FormGroup>

                <Button type="submit">Sign up</Button>
            </Form>

        </FormSection>
    </AuthLayout>
}

export default SignUp