import { Reducer, AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import webAuth0 from '../components/auth/auth0Config'
import { Auth0UserProfile } from 'auth0-js'
import history from '../history'

export interface AuthUserProfile {
	displayName: string,
	email: string,
	emailVerified: boolean,
	isSso: boolean,
	accessToken: string
}

export interface AuthState {
	isLoggedIn: boolean | undefined,
	identity: AuthUserProfile | null,
	userKey: string | null
}

export interface AuthStateChanged { type: 'AUTH_STATE_CHANGED', payload: AuthUserProfile | null }
const authenticated = (accessToken: string, authUserProfile: Auth0UserProfile): AuthStateChanged =>
	({
		type: 'AUTH_STATE_CHANGED',
		payload: {
			displayName: authUserProfile.name,
			email: authUserProfile.email!,
			emailVerified: authUserProfile.email_verified!,
			isSso: !authUserProfile.sub.startsWith('auth0'),
			accessToken
		}
	})

const unauthenticated = (): AuthStateChanged =>
	({
		type: 'AUTH_STATE_CHANGED',
		payload: null
	})

const checkEmailVerified = (authUserProfile: Auth0UserProfile) => {
	if (!authUserProfile.email_verified) {
		history.replace("/auth/signin", { message: "Your email address has not been verified. Please click the link in your email before signing in." })
		return false
	}

	return true
}

export const initialise = () =>
	(dispatch: ThunkDispatch<{}, {}, AuthStateChanged>) => {
		webAuth0.parseHash((err, hashResult) => {
			if (err) console.error(err)
			if (hashResult && hashResult.accessToken && checkEmailVerified(hashResult.idTokenPayload)) {
				dispatch(authenticated(hashResult.accessToken, hashResult.idTokenPayload))
				history.replace(hashResult.state && hashResult.state.startsWith('/') ?
					hashResult.state : '/')
			}
			else {
				webAuth0.checkSession({}, (err, sessionResult) => {
					if (err) console.error(err)
					if (sessionResult && checkEmailVerified(sessionResult.idTokenPayload)) {
						dispatch(authenticated(sessionResult.accessToken, sessionResult.idTokenPayload))
					}
					else dispatch(unauthenticated())
				})
			}
		})
	}

export const reducer: Reducer<AuthState> =
	(state: AuthState | undefined, action: AnyAction): AuthState => {
		state = state || {
			identity: null,
			userKey: null,
			isLoggedIn: undefined
		}

		switch (action.type) {
			case 'AUTH_STATE_CHANGED':
				const account = action.payload as AuthUserProfile | null

				return {
					...state,
					identity: account,
					userKey: account && account.email,
					// TODO: Currently, Github tokens report emailVerified=false - not sure if we can trust that Github email
					// addresses are verified or not.
					isLoggedIn: !!account && account.emailVerified
				}
			default:
				return state
		}
	}
