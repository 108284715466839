import React from 'react'
import { FormGroup, Label, Input, Button } from 'reactstrap'
import { FormSection, PageHeading } from '../theme/PageSection'
import { postCompany } from '../../api'
import { ProductFlagPanel, ProductNameAether, ProductNameMuxer, ProductNamePlatform } from '../customer/License'
import { RequireSupport } from '../auth/RequireUser'
import Form from '../Form'

interface State {
    name: string,
    email: string,
    aether: boolean,
    muxer: boolean,
    platform: boolean
}

export default class NewCompany extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props)
        this.state = {
            name: '',
            email: '',
            aether: false,
            muxer: false,
            platform: false
        }
    }
    submit() {
        const { name, email, aether, muxer, platform } = this.state
        return postCompany({
            name,
            contact: { email },
            licenses: { aether, muxer, platform }
        })
    }
    render() {
        const { name, email, aether, muxer, platform } = this.state
        return <React.Fragment>
            <PageHeading>New company</PageHeading>
            <FormSection>
                <Form onSubmit={this.submit.bind(this)}
                    successMessage="The new company was created.">

                    <FormGroup>
                        <Label>Company name</Label>
                        <Input type="text" name="name" required
                            value={name}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => this.setState({ name: e.currentTarget.value })} />
                    </FormGroup>

                    <FormGroup>
                        <Label>Contact email address</Label>
                        <Input type="email" name="email" required
                            value={email}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => this.setState({ email: e.currentTarget.value })} />
                    </FormGroup>

                    <RequireSupport>
                    <FormGroup>
                        <Label>Licensed products</Label>
                        <ProductFlagPanel data-testid="product-flags">
                            <div><Label> <Input type="checkbox" name="aether" checked={aether}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => this.setState({ aether: e.currentTarget.checked })} />
                                {ProductNameAether}</Label></div>
                            <div><Label> <Input type="checkbox" name="platform" checked={platform}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => this.setState({ platform: e.currentTarget.checked })} />
                                {ProductNamePlatform}</Label></div>
                        </ProductFlagPanel>
                    </FormGroup>
                    </RequireSupport>

                    <Button type="submit">Create</Button >
                    
                </Form>

            </FormSection>
        </React.Fragment>
    }
}