import * as React from 'react'
import { Redirect } from 'react-router'
import Alert from 'reactstrap/lib/Alert'
import MustBeLoggedIn from './auth/MustBeLoggedIn'
import requireUser from './auth/RequireUser'
import { UserState } from '../store/user'
import { LoadingState } from './LoadingState'
import CompanyList from './support/CompanyList'
import Layout from './Layout'

const Home = ({ user }: { user: UserState }) => {
  const companyKeys = Object.keys(user.companies);
  let content = null;

  if (user.isSupportUser) {
    content = <CompanyList />
  }
  else if (companyKeys.length) {
    content = <Redirect to={`/profile/${companyKeys[0]}`} />
  }
  else if (user.personalAccount) {
    content = <Redirect to={`/platform-sdk-downloads/`} />
  }
  else {
    content = <Alert color="warning" id="request-invitation">
      You don't have access to a company profile. Ask your company's administrator to invite you or <a href="/auth/signin">sign in</a>  with a different account.
    </Alert>;
  }

  return <MustBeLoggedIn>
    <LoadingState state={user.networkState}>
      <Layout>
        {content}
      </Layout>
    </LoadingState>
  </MustBeLoggedIn>
}

export default requireUser(Home)
