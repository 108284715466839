import React from 'react'
import { SectionHeading, SubFullWidthSection } from '../theme/PageSection'
import { RequireAdmin } from '../auth/RequireUser'
import { CompanyLoaderProps } from './CompanyLoader'

const DateFormat = ({date}:{date:Date}) => 
    <>{date.toLocaleDateString(window.navigator.language)}</>

export default (props: CompanyLoaderProps) => {
    const endDate = props.company.subscription &&
        new Date(props.company.subscription.endDate)
    return <RequireAdmin>
        <SectionHeading>Subscription</SectionHeading>
        <SubFullWidthSection id="subscription-info">
            {endDate ? 
                endDate > new Date() ?
                <p>Your product subscription ends on {' '}<DateFormat date={endDate}/>.</p> :
                <p>You do not have an active product subscription. Your subscription ended on 
                    {' '}<DateFormat date={endDate}/>.</p> :
                <p>You do not have an active product subscription.</p>
            }
        </SubFullWidthSection>
    </RequireAdmin>
}