import azureLogoSmall from '../theme/icons/azure-small.svg';
import azureLogo from '../theme/icons/azure.svg';
import awsLogoSmall from '../theme/icons/aws-small.svg';
import awsLogo from '../theme/icons/aws.svg';
import { CloudProvider } from '../api';

export type NetworkState = 'wait' | 'complete' | 'error'

export const CloudProviders: Array<CloudProvider> = [
    {
        id: "azure",
        logo: azureLogo,
        logoSmall: azureLogoSmall,
        shortDisplayName: "Azure",
        displayName: "Microsoft Azure",
        homepage: "https://portal.azure.com"
    },
    {
        id: "aws",
        logo: awsLogo,
        logoSmall: awsLogoSmall,
        shortDisplayName: "AWS",
        displayName: "Amazon Web Services",
        homepage: "https://aws.amazon.com/console/"
    },
]

export const getCloudProvider = ( providerId: string | null = null ) => {
    if (!providerId) {
        return CloudProviders[0];
    }

    let found = CloudProviders.find(p => p.id == providerId.toLowerCase());

    if (!found) {
        throw `Unsupported cloud provider: ${providerId}`;
    }

    return found;
}
