import { Action, Reducer } from 'redux'
import { fetchCurrentUser, UserRoles } from '../api'
import { ThunkDispatch } from 'redux-thunk'
import { CompanyChangedAction } from './companyProfile'
import { ApplicationState } from '.'
import { NetworkState } from './types'

type Computed = {
	networkState: NetworkState,
	companyKey: string | undefined,
	admin: boolean
}
export type UserState = UserRoles & Computed

export interface UserFetchingAction { type: 'USER_FETCHING' }
export interface UserReceivedAction { type: 'USER_RECEIVED', payload: UserRoles }
type AnyAction = UserFetchingAction | UserReceivedAction | CompanyChangedAction

export const fetchUser = () =>
	(dispatch: ThunkDispatch<{}, {}, Action>, getState: () => ApplicationState) => {
		var state = getState()
		if (!state.user || state.user.networkState !== "complete") {
			if (state.auth && state.auth.isLoggedIn) {
				dispatch({ type: 'USER_FETCHING' })
				fetchCurrentUser()
					.then((user: UserRoles) => dispatch({ type: 'USER_RECEIVED', payload: user }))
			}
		}
	}

export const reducer: Reducer<UserState> =
	(state: UserState | undefined, action: Action): UserState => {
		const a = action as AnyAction
		state = state || {
			networkState: "wait",
			isSupportUser: false,
			personalAccount: false,
			companies: {},
			companyKey: undefined,
			admin: false
		}

		switch (a.type) {
			case 'USER_FETCHING':
				state = {
					...state,
					networkState: "wait"
				}
				break
			case 'COMPANY_CHANGED':
				state = {
					...state,
					companyKey: a.payload
				}
				break
			case 'USER_RECEIVED':
				state = {
					...state,
					...a.payload,
					networkState: "complete"
				}
				break
		}

		state = {
			...state,
			admin: state.isSupportUser ||
				(!!state.companyKey
					&& state.companies[state.companyKey]
					&& state.companies[state.companyKey].admin)
		}

		return state
	}
