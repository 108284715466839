import React from 'react'
import { useState, useEffect } from 'react'
import { WideSection } from '../theme/PageSection'
import { Button, Input, } from 'reactstrap'
import { UserState } from '../../store/user'
import requireUser, { RequireSupport } from '../auth/RequireUser'
import { getSdkVersionsDescending, getPlatformSdkDownloadUrl, getStagedPlatformSdkDownloadUrl } from '../../api'
import linuxLogo from '../../theme/linux-tux.svg'
import styled from 'styled-components'
import { CopyBlock, dracula } from "react-code-blocks";
import { LoadingState, useLoadingState } from '../LoadingState'


const Title = styled.h1`
    margin-top: 10px;
`

const Description = styled.p`
`

const Warning = styled.p`
    color: #555;
`

const PlatformContainer = styled.div`
    display: flex;
`

const PlatformLogo = styled.div`
    align-self: end;
`

const PlatformContent = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
`

const PlatformDownloadCode = styled.div`
    max-width: 700px;
`

const InlinePre = styled.pre`
    display: inline;
`

const ManifestNames = ["Development", "ReleaseCandidate", "Production"]
type ManifestFunc = { setManifest: (name: string) => void }
type PlatformVersionFunc = { setVer: (version: string) => void }

const PlatformSDKDownloadLanding = ({ user }: { user: UserState }) => {
    const [manifest, setManifest] = React.useState<string>(ManifestNames[0])
    const [downloadLink, setDownloadLink] = useState<string | undefined>(undefined);

    let selectedVersion = ""
    let container = ""
    let filename = ""

    useEffect(() => {
        (async () => {
            const downloadLink = await getPlatformSdkDownloadUrl()
            setDownloadLink(downloadLink)
        })()
    }, [])

    const LinuxDownload = <>
        <hr />
        <PlatformContainer>
            <PlatformLogo>
                <img src={linuxLogo} height="100px" width="100px" />
            </PlatformLogo>
            <PlatformContent>
                <div>
                    <h2>Linux</h2>
                </div>
                <PlatformDownloadCode>
                    <CopyBlock
                        text={`wget '${downloadLink}' -qO - | tar xz && hadean-platform-sdk/install.sh && rm -rf hadean-platform-sdk`}
                        theme={dracula}
                        language="bash"
                        showLineNumbers={false}
                        wrapLines
                        codeBlock
                    />
                </PlatformDownloadCode>
            </PlatformContent>
        </PlatformContainer>
    </>

    const ManifestSelect = ({ setManifest }: ManifestFunc) => {
        return <div>
            <div>
                <p />
                <label>Download staged SDK</label>
            </div>
            <Input
                type="select"
                name="manifest"
                value={manifest}
                required
                onChange={(e: React.FormEvent<HTMLInputElement>) => setManifest(e.currentTarget.value)}>
                {
                    ManifestNames.map((val, key) =>
                        <option key={key} value={val}>{val}</option>)
                }
            </Input>
        </div>
    }

    const VersionSelect = ({ setVer }: PlatformVersionFunc) => {
        const { state, result } = useLoadingState(getSdkVersionsDescending, manifest)

        React.useEffect(() => {
            if (result) {
                selectedVersion = result.versions[0]
                container = result.container
                filename = result.filename
            }
        }, [result])

        return <LoadingState state={state}>
            <div>
                <p />
                <label>SDK Version</label>
            </div>
            <Input
                type="select"
                name="stagedVersion"
                required
                onChange={(e: React.FormEvent<HTMLInputElement>) => setVer(e.currentTarget.value)}>
                {
                    result && result.versions.map((val, key) =>
                        <option key={key} value={val}>{val}</option>)
                }
            </Input>
        </LoadingState>
    }

    const downloadStagedSdk = async () => {
        if (selectedVersion)
            window.location.href = await getStagedPlatformSdkDownloadUrl(container, selectedVersion, filename)
    }

    const AllPlatformsDownload = <>
        <Title>Download Platform SDK</Title>
        <Description>
            Below are the available downloads for the latest version of the Hadean Platform SDK.
        </Description>
        <Description>
            After installing, find the included examples in <InlinePre>~/hadean/examples</InlinePre>. Additionally, run a demo as shown in the <a href="https://docs.hadean.com/platform" rel="noopener noreferrer" target="_blank">documentation</a>.
        </Description>
        <Warning>
            The commands are only valid for 10 minutes, after which you'll have to refresh this page.
        </Warning>
        {downloadLink && LinuxDownload}
        <RequireSupport>
            <hr />
            <ManifestSelect setManifest={setManifest} />
            <VersionSelect setVer={setVersion} />
            <Button id="download-staged-sdk" onClick={downloadStagedSdk} className="btn-sm" style={{ marginRight: "15px", marginTop: "15px", marginBottom: "15px" }}>Download</Button>
        </RequireSupport>
    </>

    function setVersion(val: string) {
        selectedVersion = val
    }

    return (user.isSupportUser || user.personalAccount) && <WideSection>{AllPlatformsDownload}</WideSection>
}

export default requireUser(PlatformSDKDownloadLanding)
